import React, { useState, useEffect, useContext } from "react";
import { useDispatch, ReactReduxContext, useSelector } from "react-redux";
import Button from "@material-ui/core/Button";
import caseStates from "@_configs/caseStates.config";
import Tooltip from "@material-ui/core/Tooltip";
import CheckIcon from "@material-ui/icons/CheckCircle";
import {
  formatDatetime,
  getCurrencySign,
  validateEmail,
  isNull,
  shortnString,
  catchError,
} from "@_utils/helpers";
import WarningIcon from "@material-ui/icons/Warning";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import LangIcon from "@material-ui/icons/Language";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

import ActivateCasePopup from "@_components/popups/ActivateCasePopup/ActivateCasePopup";
import StripeInfoPopup from "@_components/popups/StripeInfoPopup/StripeInfoPopup";
import CancelCasePopup from "@_components/popups/CancelCasePopup/CancelCasePopup";
import InfoPaymentPopup from "@_components/popups/InfoPaymentPopup/InfoPaymentPopup";
import InfoLicensePopup from "@_components/popups/InfoLicensePopup/InfoLicensePopup";
import ContactInfoPopup from "@_components/popups/ContactInfoPopup/ContactInfoPopup";
import SelectEmailFlowPopup from "@_components/popups/SelectEmailFlowPopup/SelectEmailFlowPopup";
import SelectTranslationPopup from "@_components/popups/SelectTranslationPopup/SelectTranslationPopup";
import ReminderPopup from "@_components/popups/ReminderPopup/ReminderPopup";
import RenderPopup from "@_components/popups/RenderPopup/RenderPopup";
import { authService } from "@_services/auth.service";

import { templateService } from "@_services/template.service";
import ClaimPopup from "@_components/popups/ClaimPopup/ClaimPopup";
import styles from "./OverviewSection.module.css";
import NextIcon from "@material-ui/icons/NavigateNextOutlined";
import FindingsPopup from "@_components/popups/FindingsPopup/FindingsPopup";
import AddFindingPopup from "@_components/popups/AddFindingPopup/AddFindingPopup";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { languageActions } from "@_actions/language.actions";
import { emailSetupActions } from "@_actions/email_setup.actions";
import { caseActions } from "@_actions/case.actions";
import { casesActions } from "@_actions/cases.actions";
import TemplatePopup from "@_components/popups/TemplatePopup/TemplatePopup";
import { EmailOutlined } from "@material-ui/icons";
import { caseConstants } from "@_constants/case.constants";
import { emailConstants } from "@_constants/email_flows.constants";
import AreYouSurePopup from "@_components/popups/AreYouSurePopup/AreYouSurePopup";
import { ListSubheader } from "@material-ui/core";
import InfoPopup from "@_components/popups/InfoPopup/InfoPopup";
import { invoiceTemplateConstants } from "@_constants/invoiceTemplate.constants";

function OverviewSection(props) {
  const dispatch = useDispatch();
  const { data } = props;

  const emailSetupReducer = useSelector((state) => state.emailSetupReducer);
  const languageReducer = useSelector((state) => state.languageReducer);
  const caseReducer = useSelector((state) => state.caseReducer);

  const [openActivateCasePopup, setOpenActivateCasePopup] = useState(false);
  const [openStripeInfoPopup, setOpenStripeInfoPopup] = useState(false);

  const [openCancelCasePopup, setOpenCancelCasePopup] = useState(false);
  const [templatePopupOpen, setTemplatePopupOpen] = useState(false);
  const [openResetCasePopup, setOpenResetCasePopup] = useState(false);
  const [openInfoPaymentPopup, setOpenInfoPaymentPopup] = useState(false);
  const [openInfoLicensePopup, setOpenInfoLicensePopup] = useState(false);
  const [openClaimPopup, setOpenClaimPopup] = useState(false);
  const [openContactInfoPopup, setOpenContactInfoPopup] = useState(false);
  const [openReminderPopup, setOpenReminderPopup] = useState(false);
  const [reminderData, setReminderData] = useState(
    caseReducer.case[props.data.id]
  );

  const [openFindingsPopup, setOpenFindingsPopup] = useState(false);
  const [openAddFindingPopup, setOpenAddFindingPopup] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const { store } = useContext(ReactReduxContext);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMsg, setSnackbarMsg] = useState("");
  const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
  const [ErrorSnackbarMsg, setErrorSnackbarMsg] = useState("");

  const [openEmailFlowPopup, setOpenEmailFlowPopup] = useState(false);
  const [openTranslationPopup, setOpenTranslationPopup] = useState(false);
  const [templates, setTemplates] = useState([]);
  const [emailData, setEmailData] = useState({});
  const [openRenderPopup, setOpenRenderPopup] = useState(false);
  const [isTaxable, setIsTaxable] = useState(false);
  const [validTax, setValidTax] = useState(false);
  const [selectedTax, setSelectedTax] = useState("");
  const [isConnected, setIsConnected] = useState();
  const [stripeUrl, setStripeUrl] = useState();
  const [flow, setFlow] = useState();
  const [displayedContactData, setDisplayedContactData] = useState("");
  const [openPreviewMenu, setOpenPreviewMenu] = useState(false);
  const [openInfoPopup, setOpenInfoPopup] = useState(false);
  const [infoPopupText, setInfoPopupText] = useState("");
  const [InfoPopupTitle, setInfoPopupTitle] = useState("");

  const validTranslation = languageReducer.translations.default_translation_id;
  const emailSetup = emailSetupReducer.email_setup.active_email_setup;

  useEffect(() => {
    data.email_flow && data.email_flow.id
      ? setFlow(data.email_flow)
      : setFlow();
  }, [data.email_flow, flow]);

  const validContact =
    data.opponent_contact_details &&
    data.opponent_contact_details.email &&
    !isNull(data.opponent_contact_details.email) &&
    validateEmail(data.opponent_contact_details.email);

  const validClaim =
    data.charge_details &&
    data.charge_details.total_net_amount_findings > 0 &&
    ((data.claim_details.settlement_offer_date &&
      data.claim_details.settlement_offer_percentage) ||
      (!data.claim_details.settlement_offer_date &&
        !data.claim_details.settlement_offer_percentage)) &&
    data.charge_details &&
    data.charge_details.total_net_amount_findings > 0 &&
    data.country &&
    !isNull(data.country) &&
    !data.findings.some((item) => !item.price || item.price <= 0) &&
    (!data.claim_details.additional_fees ||
      !data.claim_details.additional_fees.some(
        (item) => !item.percentage || !item.name || item.price <= 0
      )) &&
    data.charge_details.currency &&
    !isNull(data.country) &&
    data.charge_details.indirect_tax_type &&
    (isTaxable ? validTax : !isTaxable);

  const activable = validContact && validClaim && flow && validTranslation;
  const currState = store.getState().casesReducer.status.CASE_ADD_FINDING;

  useEffect(() => {
    dispatch(emailSetupActions.getEmailSetup());
  }, [dispatch]);

  useEffect(() => {
    if (caseReducer.status.CASE_APPLY_TEMPLATE === "success") {
      setTemplatePopupOpen(false);
      dispatch(caseActions.clearStateMessage("CASE_APPLY_TEMPLATE"));
      dispatch(caseActions.getCaseWithChargeDetails(data.id, data));
      dispatch(casesActions.getCases());
    }
  }, [caseReducer.status.CASE_APPLY_TEMPLATE, data, dispatch]);

  useEffect(() => {
    setSelectedImage(data.findings && data.findings[0]);
  }, [data.findings]);

  useEffect(() => {
    flow && flow.id
      ? templateService.getTemplates(flow.id).then(
          (res) => {
            if (!res) res = [];
            if (data.state !== caseConstants.CASE_STATE_STANDBY) {
              res.push({
                id: emailConstants.NEW_MAIL,
                name: "New Email",
              });
            }
            setTemplates(res);
          },
          (error) => {
            console.log(error);
          }
        )
      : setTemplates([]);
  }, [flow, data.state]);

  useEffect(() => {
    dispatch(languageActions.getTranslations());
  }, [dispatch]);

  const [openMenu, setOpenMenu] = useState(null);
  const handleCloseMenu = (event) => {
    event.stopPropagation();
    setOpenMenu(null);
  };

  const handleOpenMenu = (event) => {
    event.stopPropagation();
    flow
      ? setOpenMenu(event.currentTarget)
      : handleOpenInfoPopup(
          "No email flow assigned to the case.",
          "Please select an email flow to send emails"
        );
  };

  const handleOpenPreviewMenu = (event) => {
    event.stopPropagation();
    flow
      ? setOpenPreviewMenu(event.currentTarget)
      : handleOpenInfoPopup(
          "No email flow assigned to the case.",
          "Please select an email flow to preview emails"
        );
  };

  const handleOpenInfoPopup = (title, text) => {
    setInfoPopupTitle(title);
    setInfoPopupText(text);
    setOpenInfoPopup(true);
  };

  const handleClosePreviewMenu = (event) => {
    event.stopPropagation();
    setOpenPreviewMenu(false);
  };

  const handleOpenEmailFlowPopup = (event) => {
    event.stopPropagation();
    setOpenEmailFlowPopup(true);
  };

  const handleOpenTemplatePopup = (event, template_id, name) => {
    event.stopPropagation();
    handleCloseMenu(event);
    handleClosePreviewMenu(event);
    if (template_id === emailConstants.NEW_MAIL) {
      setEmailData({
        content: "",
        name: emailConstants.NEW_MAIL,
        subject: "",
        opponentEmail: data.opponent_contact_details?.email,
      });
      setOpenRenderPopup(true);
    } else {
      templateService.getCaseTemplate(data.id, template_id).then(
        (template) => {
          setEmailData({
            content: template.content,
            name: name,
            subject: template.subject,
            opponentEmail: data.opponent_contact_details?.email,
          });
          setOpenRenderPopup(true);
        },
        (error) => {
          console.log(error);
          alert(error);
        }
      );
    }
  };

  useEffect(() => {
    if (currState === "success") {
      setOpenAddFindingPopup(false);
      setSnackbarMsg("Finding added!");
      setOpenSnackbar(true);
    }
  }, [openSnackbar, openAddFindingPopup, currState]);

  useEffect(() => {
    if (caseReducer.status.CASE_UPDATE_CLAIM_DETAILS === "success") {
      setSnackbarMsg("Success");
      setOpenSnackbar(true);
      dispatch(caseActions.clearStateMessage("CASE_UPDATE_CLAIM_DETAILS"));
    } else if (
      catchError(caseReducer.status.CASE_UPDATE_CLAIM_DETAILS) ||
      catchError(caseReducer.status.CASE_ADD_ADDITIONAL_FEES)
    ) {
      setOpenErrorSnackbar(true);
      setErrorSnackbarMsg(
        catchError(caseReducer.status.CASE_UPDATE_CLAIM_DETAILS)
          ? caseReducer.status.CASE_UPDATE_CLAIM_DETAILS
          : caseReducer.status.CASE_ADD_ADDITIONAL_FEES
      );
      dispatch(caseActions.clearStateMessage("CASE_UPDATE_CLAIM_DETAILS"));
    }
  }, [
    caseReducer.status.CASE_UPDATE_CLAIM_DETAILS,
    caseReducer.status.CASE_ADD_ADDITIONAL_FEES,
    dispatch,
  ]);

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const langSelectionCLicked = () => {
    if (validTranslation) {
      setOpenTranslationPopup(true);
    } else {
      alert(
        "You must create and activate a translation under Admin > Settlement Portal > Translations before activating this case."
      );
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
    setOpenErrorSnackbar(false);
    setErrorSnackbarMsg("");
    setSnackbarMsg("");
  };

  const openEvidancePopup = () => {
    if (data.findings.length > 0) setOpenFindingsPopup(data.findings);
    else setOpenAddFindingPopup(true);
  };

  const handleCaseActivation = () => {
    authService.getStripe().then(
      (results) => {
        if (results.status === "awaiting_onboarding") {
          setStripeUrl(results.onboarding_url);
          setIsConnected(false);
          setOpenStripeInfoPopup(true);
        } else {
          setIsConnected(true);
          setOpenActivateCasePopup(true);
          // either connected or diffrent status response with 200
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };

  useEffect(() => {
    if (
      data.claim_details.indirect_tax_type &&
      data.claim_details.indirect_tax_type !==
        caseConstants.CASE_TAX_TYPE_NO_INDIRECT_TAX &&
      data.claim_details.indirect_tax_type !==
        caseConstants.CASE_TAX_TYPE_REVERSE_CHARGE
    ) {
      setIsTaxable(true);
      if (
        !isNull(data.claim_details.indirect_tax_percentage) &&
        data.claim_details.indirect_tax_percentage >= 0
      ) {
        setValidTax(true);
      } else setValidTax(false);
    } else setIsTaxable(false);
  }, [
    data.claim_details.indirect_tax_type,
    data.claim_details.indirect_tax_percentage,
  ]);

  useEffect(() => {
    if (
      data.charge_details &&
      data.charge_details.indirect_tax_type === "SALES_TAX"
    ) {
      setSelectedTax("Sales Tax");
    } else if (
      data.charge_details &&
      data.charge_details.indirect_tax_type === "VAT"
    ) {
      setSelectedTax("VAT");
    } else if (
      data.charge_details &&
      data.charge_details.indirect_tax_type === "GST"
    ) {
      setSelectedTax("GST");
    } else setSelectedTax("");
  }, [data.charge_details]);

  useEffect(() => {
    if (data.opponent_contact_details) {
      if (
        data.opponent_contact_details.email &&
        data.opponent_contact_details.name
      ) {
        setDisplayedContactData(
          data.opponent_contact_details.email +
            " - " +
            data.opponent_contact_details.name
        );
      } else if (data.opponent_contact_details.email) {
        setDisplayedContactData(data.opponent_contact_details.email);
      } else if (data.opponent_contact_details.name) {
        setDisplayedContactData(data.opponent_contact_details.name);
      }
    } else {
      setDisplayedContactData("No contact details set");
    }
  }, [data.opponent_contact_details, displayedContactData]);

  useEffect(() => {
    const responseStatus = caseReducer.status.CASE_SAVE_EMAIL_TO_LOG;
    if (responseStatus && responseStatus === "success") {
      dispatch(caseActions.getCaseWithChargeDetails(data.id, data.overview));
    }
  }, [caseReducer.status.CASE_SAVE_EMAIL_TO_LOG, dispatch, data]);

  const handleResetCase = () => {
    data.was_activated
      ? dispatch(caseActions.updateCaseStateActivate(data.id))
      : dispatch(caseActions.updateCaseStateReset(data.id));
  };

  useEffect(() => {
    if (
      caseReducer.status.CASE_UPDATE_STATE_RESET === "success" ||
      caseReducer.status.CASE_UPDATE_STATE_ACTIVATE === "success" ||
      caseReducer.status.CASE_UPDATE_STATE_ACTIVATE === "not sent"
    ) {
      dispatch(casesActions.getCases());
      dispatch(casesActions.getCasesCount());
      setTimeout(() => {
        dispatch(caseActions.getCaseWithChargeDetails(data.id, data.overview));
        setOpenResetCasePopup(false);
        dispatch(caseActions.clearStateMessage("CASE_UPDATE_STATE_RESET"));
        dispatch(caseActions.clearStateMessage("CASE_UPDATE_STATE_ACTIVATE"));
      }, 100);
    } else if (
      openResetCasePopup &&
      (catchError(caseReducer.status.CASE_UPDATE_STATE_RESET) ||
        catchError(caseReducer.status.CASE_UPDATE_STATE_ACTIVATE))
    ) {
      setErrorSnackbarMsg(
        catchError(caseReducer.status.CASE_UPDATE_STATE_RESET)
          ? caseReducer.status.CASE_UPDATE_STATE_RESET
          : caseReducer.status.CASE_UPDATE_STATE_ACTIVATE
      );
      setOpenErrorSnackbar(true);
      setOpenResetCasePopup(false);
      dispatch(caseActions.clearStateMessage("CASE_UPDATE_STATE_RESET"));
      dispatch(caseActions.clearStateMessage("CASE_UPDATE_STATE_ACTIVATE"));
    }
  }, [
    caseReducer.status.CASE_UPDATE_STATE_RESET,
    caseReducer.status.CASE_UPDATE_STATE_ACTIVATE,
    openResetCasePopup,
    dispatch,
    data.overview,
    data.id,
  ]);

  useEffect(() => {
    const state = caseReducer.status.CASE_UPDATE_STATE_ACTIVATE;
    if (state === "not sent" && !data.overview?.was_activated) {
      dispatch(caseActions.clearStateMessage("CASE_UPDATE_STATE_ACTIVATE"));
      dispatch(casesActions.getCases());
      dispatch(casesActions.getCasesCount());
      dispatch(caseActions.getCaseWithChargeDetails(data.id, data.overview));
      handleOpenInfoPopup(
        "Initial email not sent",
        "The case is now activated, but the initial email could not be sent. The reason is shown in the failed email event in the case activity section. Try resending the email manually after having resolved the issue."
      );
      setOpenActivateCasePopup(false);
    }
  }, [
    caseReducer.status.CASE_UPDATE_STATE_ACTIVATE,
    dispatch,
    data.id,
    data.overview,
  ]);

  return (
    <div>
      <div className={styles.root}>
        <div className={styles.activation_section}>
          <div className={styles.status_container}>
            <div className={styles.status_box}>
              <div className={styles.status_icon}>
                {caseStates[data.state].icon}
              </div>
              <div className={styles.status_name}>
                {caseStates[data.state].name}
              </div>
            </div>
            <div className={styles.status_date}>
              {formatDatetime(data.date_created, "short")}
            </div>
          </div>

          <div className={styles.host_container}>
            <div className={styles.host}>{data.host}</div>
            <div className={styles.source_id}>
              {data.source_case_id} Case ID: {data.case_id}
            </div>
          </div>

          <div className={styles.btn_container}>
            {data.state === "STANDBY" ? (
              <div className={styles.activate_btns_container}>
                <Tooltip title={"Cancel case"} placement="top-start">
                  <div
                    className={styles.cancel_icon}
                    onClick={() => setOpenCancelCasePopup(true)}
                  >
                    {caseStates["CANCELLED"].icon}
                  </div>
                </Tooltip>
                <Button
                  className={styles.btn_activate}
                  onClick={() => handleCaseActivation()}
                  disabled={!activable}
                >
                  ACTIVATE
                </Button>
              </div>
            ) : data.state === "ACTIVE" ? (
              <Tooltip
                title={
                  data.invoices?.some(
                    (invoice) =>
                      invoice.type ===
                      invoiceTemplateConstants.INVOICE_TYPE_ACTIVE
                  )
                    ? "This case contains an active invoice and can't be cancelled."
                    : ""
                }
                placement="top"
                arrow
              >
                <span>
                  <Button
                    className={styles.btn_cancel}
                    onClick={() => setOpenCancelCasePopup(true)}
                    disabled={data.invoices?.some(
                      (invoice) =>
                        invoice.type ===
                        invoiceTemplateConstants.INVOICE_TYPE_ACTIVE
                    )}
                  >
                    CANCEL
                  </Button>
                </span>
              </Tooltip>
            ) : data.state === "PAID" ? (
              <></>
            ) : // <Button className={styles.btn_payment} onClick={()=>setOpenInfoPaymentPopup(true)}>PAYMENT INFO</Button>
            data.state === "VALID_LICENSE" ? (
              <></>
            ) : // <Button className={styles.btn_payment} onClick={()=>setOpenInfoLicensePopup(true)}>LICENSE INFO</Button>
            data.state === "CANCELLED" ? (
              <Button
                className={styles.btn_reset}
                onClick={() => setOpenResetCasePopup(true)}
              >
                {data.was_activated ? "REACTIVATE" : "RESET"}
              </Button>
            ) : (
              <></>
            )}
          </div>
        </div>
        <div className={styles.details_container}>
          <div className={styles.finding_section}>
            <div className={styles.image_list}>
              {data.findings && data.findings.length > 0 ? (
                <>
                  <div className={styles.no_finding_container_with_image}>
                    {(data.state === "STANDBY" ||
                      data.state === caseConstants.CASE_STATE_ACTIVE) && (
                      <div className={styles.btn_add_finding_with_image}>
                        <Button
                          className={styles.btn_add_finding}
                          onClick={() => setOpenAddFindingPopup(true)}
                        >
                          <b className={styles.plus_sign}>+</b>Add image use
                        </Button>
                      </div>
                    )}
                  </div>
                  <img
                    className={styles.image_item}
                    src={
                      selectedImage &&
                      selectedImage.original_image.original_image_url
                    }
                    alt="original url"
                    key={selectedImage && selectedImage.id}
                  />
                  <div className={styles.image_icons_container}>
                    {data.findings &&
                      data.findings.map((finding) => (
                        <img
                          className={
                            finding === selectedImage && selectedImage
                              ? styles.image_icon_selected
                              : styles.image_icon
                          }
                          src={finding.original_image.original_image_url}
                          alt="original url"
                          key={finding.id}
                          onMouseEnter={() => setSelectedImage(finding)}
                        />
                      ))}
                  </div>
                </>
              ) : (
                <div className={styles.no_finding_container}>
                  <div className={styles.add_finding_container}>
                    <Button
                      className={styles.btn_add_finding}
                      onClick={() => setOpenAddFindingPopup(true)}
                    >
                      <b className={styles.plus_sign}>+</b>Add image use
                    </Button>
                  </div>
                </div>
              )}
            </div>
            <div
              className={styles.evidence_container}
              onClick={openEvidancePopup}
            >
              <div className={styles.evidence_text}>Image uses</div>
              <div className={styles.evidence_action}>
                <div className={styles.finding_count}>
                  {data.findings ? data.findings.length : 0} pic(s)
                </div>
                <NextIcon />
              </div>
            </div>
          </div>
          <div className={styles.overview_section}>
            {data.state === "STANDBY" && (
              <>
                <div className={styles.case_templage_container}>
                  <button
                    style={{ padding: "4px 4px 4px 4px", fontFamily: "Roboto" }}
                    onClick={() => setTemplatePopupOpen(true)}
                  >
                    AUTO FILL
                  </button>
                </div>
              </>
            )}

            <div
              className={`${styles.contact_container} `}
              onClick={langSelectionCLicked}
            >
              {data.state !== "STANDBY" ? (
                <></>
              ) : validTranslation ? (
                <div className={styles.valid_box}>
                  <CheckIcon />
                </div>
              ) : (
                <div className={styles.not_valid_box}>
                  <WarningIcon />
                </div>
              )}
              <div className={styles.title}>Portal Language</div>
              <div
                className={styles.template_container}
                href={"#"}
                rel="noopener noreferrer"
              >
                <div className={styles.template_icon}>
                  <LangIcon />
                </div>

                <div className={styles.template_title}>
                  {(data.translation &&
                    data.translation.language.toUpperCase()) ||
                    (languageReducer.translations &&
                      languageReducer.translations.default_translation_id &&
                      "DEFAULT") ||
                    "No active translation"}
                </div>
              </div>
            </div>

            <hr />

            <div
              className={`${styles.email_flow_container} ${
                data.state !== caseConstants.CASE_STATE_STANDBY &&
                data.state !== caseConstants.CASE_STATE_CANCELLED
                  ? styles.no_hover
                  : ""
              }`}
              onClick={
                data.state === caseConstants.CASE_STATE_STANDBY ||
                data.state === caseConstants.CASE_STATE_CANCELLED
                  ? handleOpenEmailFlowPopup
                  : () => {}
              }
            >
              <div
                className={`${styles.email_flow_left_container} ${
                  data.state !== caseConstants.CASE_STATE_STANDBY &&
                  data.state !== caseConstants.CASE_STATE_CANCELLED
                    ? styles.disabled
                    : ""
                }`}
              >
                <div className={styles.title}>Email Flow</div>
                <div
                  className={styles.template_container}
                  href={"#"}
                  rel="noopener noreferrer"
                >
                  <div className={styles.template_icon}>
                    <EmailOutlined />
                  </div>

                  <div className={styles.template_title}>
                    {(flow && flow.name) || "No email flow is set"}
                  </div>
                </div>
              </div>

              {data.state !== "STANDBY" ? (
                <></>
              ) : flow ? (
                <div className={styles.valid_box}>
                  <CheckIcon />
                </div>
              ) : (
                <div className={styles.not_valid_box}>
                  <WarningIcon />
                </div>
              )}
              {(data.state === "ACTIVE" ||
                data.state === "VALID_LICENSE" ||
                data.state === "PAYMENT_PROCESSING" ||
                data.state === "PAID" ||
                data.state === "CANCELLED") && (
                <>
                  <Button
                    variant="outlined"
                    color="default"
                    className={styles.btn_send_email}
                    onClick={handleOpenMenu}
                    endIcon={<ArrowDropDownIcon />}
                  >
                    Send Email
                  </Button>
                  <Menu
                    id="simple-menu"
                    anchorEl={openMenu}
                    keepMounted={false}
                    open={Boolean(openMenu)}
                    onClose={handleCloseMenu}
                  >
                    {templates
                      .filter(
                        (template) =>
                          template.type ===
                            emailConstants.TEMPLATE_TYPE_CUSTOM ||
                          template.id === emailConstants.NEW_MAIL
                      )
                      .map((template, index, filteredTemplates) => (
                        <MenuItem
                          className={
                            index === filteredTemplates.length - 1
                              ? styles.template_dropdown_last
                              : styles.template_dropdown
                          }
                          key={index}
                          onClick={(event) =>
                            handleOpenTemplatePopup(
                              event,
                              template.id,
                              template.name
                            )
                          }
                        >
                          {template.name}
                        </MenuItem>
                      ))}
                  </Menu>
                </>
              )}
              {data.state === caseConstants.CASE_STATE_STANDBY && (
                <>
                  <Button
                    variant="outlined"
                    color="default"
                    className={styles.btn_send_email}
                    onClick={handleOpenPreviewMenu}
                    endIcon={<ArrowDropDownIcon />}
                  >
                    Preview email
                  </Button>
                  <Menu
                    id="simple-menu"
                    anchorEl={openPreviewMenu}
                    keepMounted={false}
                    open={Boolean(openPreviewMenu)}
                    onClose={handleClosePreviewMenu}
                    onClick={(event) => event.stopPropagation()}
                  >
                    {templates?.length > 0 ? (
                      <>
                        {templates.some(
                          (template) =>
                            template.type ===
                              emailConstants.TEMPLATE_TYPE_TRIGGERED &&
                            template.trigger_event ===
                              emailConstants.TRIGGER_EVENT_INITIAL
                        ) && (
                          <>
                            <ListSubheader>INITIAL EMAIL</ListSubheader>
                            {templates
                              .filter(
                                (template) =>
                                  template.type ===
                                    emailConstants.TEMPLATE_TYPE_TRIGGERED &&
                                  template.trigger_event ===
                                    emailConstants.TRIGGER_EVENT_INITIAL
                              )
                              .map((template, index) => (
                                <MenuItem
                                  className={styles.template_dropdown}
                                  key={index}
                                  onClick={(event) =>
                                    handleOpenTemplatePopup(event, template.id)
                                  }
                                >
                                  {template.name}
                                </MenuItem>
                              ))}
                          </>
                        )}
                        {templates.some(
                          (template) =>
                            template.type ===
                            emailConstants.TEMPLATE_TYPE_SCHEDULED
                        ) && (
                          <>
                            <ListSubheader>
                              {emailConstants.TEMPLATE_TYPE_SCHEDULED} EMAILS
                            </ListSubheader>
                            {templates
                              .filter(
                                (template) =>
                                  template.type ===
                                  emailConstants.TEMPLATE_TYPE_SCHEDULED
                              )
                              .map((template, index) => (
                                <MenuItem
                                  className={styles.template_dropdown}
                                  key={index}
                                  onClick={(event) =>
                                    handleOpenTemplatePopup(event, template.id)
                                  }
                                >
                                  {template.name}
                                </MenuItem>
                              ))}
                          </>
                        )}
                        {templates.some(
                          (template) =>
                            template.type ===
                              emailConstants.TEMPLATE_TYPE_TRIGGERED &&
                            template.trigger_event !==
                              emailConstants.TRIGGER_EVENT_INITIAL
                        ) && (
                          <>
                            <ListSubheader>
                              EVENT TRIGGERED EMAILS
                            </ListSubheader>
                            {templates
                              .filter(
                                (template) =>
                                  template.type ===
                                    emailConstants.TEMPLATE_TYPE_TRIGGERED &&
                                  template.trigger_event !==
                                    emailConstants.TRIGGER_EVENT_INITIAL
                              )
                              .map((template, index) => (
                                <MenuItem
                                  className={styles.template_dropdown}
                                  key={index}
                                  onClick={(event) =>
                                    handleOpenTemplatePopup(event, template.id)
                                  }
                                >
                                  {template.name}
                                </MenuItem>
                              ))}
                          </>
                        )}
                        {templates.some(
                          (template) =>
                            template.type ===
                            emailConstants.TEMPLATE_TYPE_CUSTOM
                        ) && (
                          <>
                            <ListSubheader>
                              {emailConstants.TEMPLATE_TYPE_CUSTOM} EMAILS
                            </ListSubheader>
                            {templates
                              .filter(
                                (template) =>
                                  template.type ===
                                  emailConstants.TEMPLATE_TYPE_CUSTOM
                              )
                              .map((template, index) => (
                                <MenuItem
                                  className={styles.template_dropdown}
                                  key={index}
                                  onClick={(event) =>
                                    handleOpenTemplatePopup(event, template.id)
                                  }
                                >
                                  {template.name}
                                </MenuItem>
                              ))}
                          </>
                        )}{" "}
                      </>
                    ) : (
                      <MenuItem
                        onClick={(event) => event.stopPropagation()}
                        className={styles.template_dropdown}
                      >
                        This email flow contains no templates to be previewed.
                      </MenuItem>
                    )}
                  </Menu>
                </>
              )}
            </div>
            <hr />
            <div
              className={styles.contact_container}
              onClick={() => setOpenContactInfoPopup(true)}
            >
              {data.state !== "STANDBY" ? (
                <></>
              ) : validContact ? (
                <div className={styles.valid_box}>
                  <CheckIcon />
                </div>
              ) : (
                <div className={styles.not_valid_box}>
                  <WarningIcon />
                </div>
              )}
              <div className={styles.title}>Website Owner Contact Info</div>

              <div className={styles.contact_info_box}>
                <div className={styles.contact_big_title}>
                  {displayedContactData}
                </div>
              </div>
            </div>
            <hr />
            <div
              className={styles.claim_container}
              onClick={() => setOpenClaimPopup(true)}
            >
              {data.state !== "STANDBY" ? (
                <></>
              ) : validClaim ? (
                <div className={styles.valid_box}>
                  <CheckIcon />
                </div>
              ) : (
                <div className={styles.not_valid_box}>
                  <WarningIcon />
                </div>
              )}
              <div className={styles.title}>Licensing Offer</div>
              <div className={styles.claim_content}>
                <div>
                  <div>
                    <div className={styles.claim_info_box}>
                      <div className={styles.claim_small_title_component}>
                        Country
                      </div>
                      <div className={styles.claim_big_title}>
                        {data.state === "VALID_LICENSE"
                          ? "----"
                          : data.country || "----"}
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className={styles.claim_info_box}>
                      <div className={styles.claim_small_title}>
                        Net License Fee
                      </div>
                      <div className={styles.claim_big_title}>
                        {data.state === "VALID_LICENSE" ||
                        (data.charge_details &&
                          !data.charge_details.total_net_amount_findings)
                          ? "----"
                          : getCurrencySign(data.charge_details.currency) +
                            " " +
                            data.charge_details.total_net_amount_findings}
                      </div>
                    </div>
                    <div className={styles.claim_info_box}>
                      <div className={styles.claim_small_title}>
                        Gross Incl.{" "}
                        {data.charge_details &&
                          data.charge_details.indirect_tax_type !==
                            caseConstants.CASE_TAX_TYPE_NO_INDIRECT_TAX &&
                          data.charge_details.indirect_tax_type !==
                            caseConstants.CASE_TAX_TYPE_REVERSE_CHARGE &&
                          selectedTax + " & "}{" "}
                        Fees
                      </div>
                      <div className={styles.claim_big_title}>
                        {data.state === "VALID_LICENSE" ? (
                          "----"
                        ) : (
                          <span>
                            <span>
                              {data.charge_details &&
                              data.charge_details
                                .final_amount_gross_no_settlement
                                ? getCurrencySign(data.claim_details.currency) +
                                  " "
                                : ""}
                            </span>
                            <span>
                              {data.charge_details &&
                              data.charge_details
                                .final_amount_gross_no_settlement
                                ? data.charge_details
                                    .final_amount_gross_no_settlement
                                : "Not Set"}
                            </span>
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <div className={styles.claim_info_box}>
                    <div className={styles.claim_small_title}>Discount</div>
                    <div className={styles.claim_big_title}>
                      {data.state === "VALID_LICENSE"
                        ? "----"
                        : data.charge_details &&
                          data.charge_details.settlement_offer &&
                          data.charge_details.settlement_offer
                            .settlement_percentage
                        ? "% " +
                          data.charge_details.settlement_offer
                            .settlement_percentage
                        : "Not Set"}
                    </div>
                  </div>
                  <div className={styles.claim_info_box}>
                    <div className={styles.claim_small_title}>
                      Discount Deadline
                    </div>
                    <div className={styles.claim_big_title}>
                      {data.state === "VALID_LICENSE"
                        ? "----"
                        : data.charge_details &&
                          data.charge_details.settlement_offer
                        ? formatDatetime(
                            data.charge_details.settlement_offer
                              .settlement_date,
                            "short"
                          )
                        : "Not Set"}
                    </div>
                  </div>
                  <div className={styles.claim_info_box}>
                    <div className={styles.claim_small_title}>
                      Discounted Total
                    </div>
                    <div className={styles.claim_big_title}>
                      {data.state === "VALID_LICENSE" ? (
                        "----"
                      ) : (
                        <span>
                          <span>
                            {data.charge_details &&
                            data.charge_details.final_amount_gross_settlement
                              ? getCurrencySign(data.claim_details.currency) +
                                " "
                              : ""}
                          </span>
                          <span>
                            {data.charge_details &&
                            data.charge_details.final_amount_gross_settlement
                              ? data.charge_details
                                  .final_amount_gross_settlement
                              : "Not Set"}
                          </span>
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            {data.state === "ACTIVE" && (
              <>
                <div className={styles.deadline_container}>
                  <div className={styles.deadline_title_container}>
                    <div className={styles.title}>Reminder</div>
                    <div>
                      <Button
                        variant="outlined"
                        color="default"
                        className={styles.btn_add_deadline}
                        onClick={() => {
                          setReminderData(caseReducer.case[data.id]);
                          setOpenReminderPopup(true);
                        }}
                      >
                        {data.reminder_name ? "Edit Reminder" : "Set Reminder"}
                      </Button>
                    </div>
                  </div>
                  <div className={styles.deadline_content}>
                    <div className={styles.deadline_info_box}>
                      <div className={styles.deadline_small_title_component}>
                        Title
                      </div>
                      <div
                        className={styles.deadline_big_title}
                        title={data.reminder_name}
                      >
                        {data.reminder_name
                          ? shortnString(data.reminder_name, 17)
                          : "----"}
                      </div>
                    </div>

                    <div className={styles.deadline_info_box}>
                      <div className={styles.deadline_small_title_component}>
                        Date
                      </div>
                      <div className={styles.deadline_big_title}>
                        {data.reminder_date
                          ? formatDatetime(data.reminder_date, "short")
                          : "----"}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      {data.state === "STANDBY" && (
        <>
          <ActivateCasePopup
            open={openActivateCasePopup}
            close={() => setOpenActivateCasePopup(false)}
            data={data}
            caseId={data.id}
            stripeConnected={isConnected}
          />
          <StripeInfoPopup
            open={openStripeInfoPopup}
            close={() => setOpenStripeInfoPopup(false)}
            stripeUrl={stripeUrl}
          />
        </>
      )}
      <InfoPopup
        open={openInfoPopup}
        close={() => setOpenInfoPopup(false)}
        title={InfoPopupTitle}
      >
        <p>{infoPopupText}</p>
      </InfoPopup>
      <CancelCasePopup
        open={openCancelCasePopup}
        close={() => setOpenCancelCasePopup(false)}
        data={data}
        caseId={data.id}
        overviewData={data.overview}
      />
      <TemplatePopup
        open={templatePopupOpen}
        close={() => setTemplatePopupOpen(false)}
        data={data}
        caseId={data.id}
        overviewData={data.overview}
      />
      <AreYouSurePopup
        open={openResetCasePopup}
        close={() => setOpenResetCasePopup(false)}
        onClick={handleResetCase}
      >
        Are you sure you want to {data.was_activated ? "reactivate" : "reset"}{" "}
        this case?
      </AreYouSurePopup>

      <InfoPaymentPopup
        open={openInfoPaymentPopup}
        close={() => setOpenInfoPaymentPopup(false)}
        data={data}
        caseId={data.id}
      />

      <InfoLicensePopup
        open={openInfoLicensePopup}
        close={() => setOpenInfoLicensePopup(false)}
        data={data}
        caseId={data.id}
      />
      <ClaimPopup
        open={openClaimPopup}
        close={() => setOpenClaimPopup(false)}
        data={data}
        caseId={data.id}
      />
      <ContactInfoPopup
        open={openContactInfoPopup}
        close={() => setOpenContactInfoPopup(false)}
        data={data}
        opponentData={data.opponent_contact_details}
        caseId={data.id}
      />

      <FindingsPopup
        open={openFindingsPopup}
        close={() => setOpenFindingsPopup(false)}
        data={data}
        overviewData={data.overview}
        imprintData={data.website_contact_details}
        findingsData={data.findings}
        caseId={data.id}
      />
      <AddFindingPopup
        open={openAddFindingPopup}
        close={() => setOpenAddFindingPopup(false)}
        caseId={data.id}
        allData={data}
      />

      <SelectEmailFlowPopup
        open={openEmailFlowPopup}
        close={() => setOpenEmailFlowPopup(false)}
        data={data}
        caseId={data.id}
        defaultFlow={flow}
      />
      <SelectTranslationPopup
        open={openTranslationPopup}
        close={() => setOpenTranslationPopup(false)}
        data={data}
        caseId={data.id}
      />

      {openReminderPopup && (
        <ReminderPopup
          open={openReminderPopup}
          close={() => setOpenReminderPopup(false)}
          data={reminderData}
          caseId={data.id}
          doClose={() => setOpenReminderPopup(false)}
        />
      )}
      <RenderPopup
        title={`Email preview: ${
          emailData.name === emailConstants.NEW_MAIL
            ? "New email"
            : emailData.name
        }`}
        open={openRenderPopup}
        close={() => setOpenRenderPopup(false)}
        data={emailData}
        caseId={data.id}
        emailSetup={emailSetup}
      />

      <Snackbar
        open={openSnackbar}
        autoHideDuration={4000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="success">
          {snackbarMsg}
        </Alert>
      </Snackbar>
      <Snackbar
        open={openErrorSnackbar}
        autoHideDuration={4000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="error">
          {ErrorSnackbarMsg}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default OverviewSection;
