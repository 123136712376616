import React from "react";
import styles from "./CaseDetails.module.css";
import OverviewSection from "@_components/sections/OverviewSection/OverviewSection";
import PaymentInfoSection from "@_components/sections/PaymentInfoSection/PaymentInfoSection";
import CaseActivitySection from "@_components/sections/CaseActivitySection/CaseActivitySection";

function CaseDetails(props) {
  const { data } = props;

  if (!data) {
    return (
      <div className={styles.root}>
        <div className={styles.no_data}>No Case is Selected!</div>
      </div>
    );
  }

  return (
    <div className={styles.root}>
      <div className={styles.top_container}>
        <OverviewSection data={data} />
      </div>
      {data.state === "PAID" && (
        <div className={styles.section_payment_info}>
          <PaymentInfoSection data={data} />
        </div>
      )}
      <div className={styles.section_container}>
        <CaseActivitySection data={data} />
      </div>
    </div>
  );
}

export default CaseDetails;
