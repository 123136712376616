import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "@_actions/user.actions";
import { catchError, getUserDataFromJwt } from "@_utils/helpers";
import subscriptionCopy from "@_configs/subscription.config.js";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Button from "@material-ui/core/Button";
import UserRow from "@_components/UserRow/UserRow";
import UserContent from "@_components/UserContent/UserContent";
import UpgradePlanpopup from "@_components/popups/UpgradePlanPopup/UpgradePlanPopup.js";
import UpgradeImage from "@_assets/images/add_user.png";
import styles from "./UserManagement.module.css";

function UserManagement() {
  const dispatch = useDispatch();
  const userReducer = useSelector((state) => state.userReducer);
  const jwtData = getUserDataFromJwt();

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [displayUpgrade, setDisplayUpgrade] = useState(false);
  const subscriptionReducer = useSelector((state) => state.subscriptionReducer);
  const { title, subtitle, copy_1, copy_2, upgradeButton } =
    subscriptionCopy.addUser[jwtData.subscription.toLowerCase()];

  useEffect(() => {
    dispatch(userActions.getUsers());
  }, [dispatch]);

  const handleCreateNewUser = () => {
    if (
      jwtData.subscription.toLowerCase() === "lite" &&
      userReducer.users.active >=
        subscriptionReducer.subscriptionConfig.max_users
    ) {
      setDisplayUpgrade(true);
    } else {
      dispatch(userActions.addUserState(true));
      dispatch(userActions.selectUser({}));
    }
  };

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
    setOpenErrorSnackbar(false);
  };

  // ERROR AND SUCCESS MESSAGES

  // display POST success & error
  useEffect(() => {
    if (userReducer.status.USER_POST === "success") {
      setSuccessMessage("Success!");
      setOpenSnackbar(true);
      setTimeout(() => {
        dispatch(userActions.clearStatusMessage("USER_POST"));
        setErrorMessage(false);
        setOpenSnackbar(false);
      }, 4000);
    } else if (catchError(userReducer.status.USER_POST)) {
      setErrorMessage(userReducer.status.USER_POST);
      setOpenErrorSnackbar(true);
      setTimeout(() => {
        dispatch(userActions.clearStatusMessage("USER_POST"));
        setOpenErrorSnackbar(false);

        setErrorMessage(false);
      }, 7000);
    } else return;
  }, [dispatch, userReducer.status]);

  // display PUT success & error
  useEffect(() => {
    if (userReducer.status.USER_PUT === "success") {
      setSuccessMessage("Success!");
      setOpenSnackbar(true);
      setTimeout(() => {
        dispatch(userActions.clearStatusMessage("USER_PUT"));
        setErrorMessage("");
        setOpenSnackbar(false);
      }, 4000);
    } else if (catchError(userReducer.status.USER_PUT)) {
      setErrorMessage(userReducer.status.USER_PUT);
      setOpenErrorSnackbar(true);
      setTimeout(() => {
        dispatch(userActions.clearStatusMessage("USER_PUT"));
        setOpenErrorSnackbar(false);
        setErrorMessage("");
      }, 7000);
    } else return;
  }, [dispatch, userReducer.status]);

  // display activation success & error
  useEffect(() => {
    if (userReducer.status.USER_ACTIVATE_PUT === "success") {
      setSuccessMessage("Success!");
      setOpenSnackbar(true);
      setTimeout(() => {
        dispatch(userActions.clearStatusMessage("USER_ACTIVATE_PUT"));
        setErrorMessage(false);
        setOpenSnackbar(false);
      }, 4000);
    } else if (catchError(userReducer.status.USER_ACTIVATE_PUT)) {
      setErrorMessage(userReducer.status.USER_ACTIVATE_PUT);
      setOpenErrorSnackbar(true);
      setTimeout(() => {
        dispatch(userActions.clearStatusMessage("USER_ACTIVATE_PUT"));
        setOpenErrorSnackbar(false);
        setErrorMessage(false);
      }, 4000);
    } else return;
  }, [dispatch, userReducer.status]);

  // display deactivation success & error
  useEffect(() => {
    if (userReducer.status.USER_DEACTIVATE_PUT === "success") {
      setSuccessMessage("Success!");
      setOpenSnackbar(true);
      setTimeout(() => {
        dispatch(userActions.clearStatusMessage("USER_DEACTIVATE_PUT"));
        setErrorMessage(false);
        setOpenSnackbar(false);
      }, 4000);
    } else if (catchError(userReducer.status.USER_DEACTIVATE_PUT)) {
      setErrorMessage(userReducer.status.USER_DEACTIVATE_PUT);
      setOpenErrorSnackbar(true);
      setTimeout(() => {
        dispatch(userActions.clearStatusMessage("USER_DEACTIVATE_PUT"));
        setOpenErrorSnackbar(false);
        setErrorMessage(false);
      }, 4000);
    } else return;
  }, [dispatch, userReducer.status]);

  // display user DELETE success & error
  useEffect(() => {
    if (userReducer.status.USER_DELETE === "success") {
      setSuccessMessage("deletion successful!");
      setOpenSnackbar(true);
      setTimeout(() => {
        dispatch(userActions.clearStatusMessage("USER_DELETE"));
        setErrorMessage("");
        setOpenSnackbar(false);
      }, 4000);
    } else if (catchError(userReducer.status.USER_DELETE)) {
      setOpenErrorSnackbar(true);
      setErrorMessage(userReducer.status.USER_DELETE);
      setTimeout(() => {
        dispatch(userActions.clearStatusMessage("USER_DELETE"));
        setErrorMessage("");
        setOpenErrorSnackbar(false);
      }, 7000);
    } else return;
  }, [openSnackbar, dispatch, userReducer.status]);

  useEffect(() => {
    if (userReducer.status.USER_ACTIVATE_PUT === "success") {
      dispatch(userActions.getUsers());
    }
  }, [userReducer.status.USER_ACTIVATE_PUT, dispatch]);

  useEffect(() => {
    if (userReducer.status.USER_DEACTIVATE_PUT === "success") {
      dispatch(userActions.getUsers());
    }
  }, [userReducer.status.USER_DEACTIVATE_PUT, dispatch]);

  useEffect(() => {
    if (userReducer.status.USER_POST === "success") {
      dispatch(userActions.getUsers());
    }
  }, [userReducer.status.USER_POST, dispatch]);

  useEffect(() => {
    if (userReducer.status.USER_PUT === "success") {
      dispatch(userActions.getUsers());
    }
  }, [userReducer.status.USER_PUT, dispatch]);

  useEffect(() => {
    if (userReducer.status.USER_DELETE === "success") {
      dispatch(userActions.getUsers());
    }
  }, [userReducer.status.USER_DELETE, dispatch]);

  return (
    <div className={styles.root}>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={4000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="success">
          {successMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        open={openErrorSnackbar}
        autoHideDuration={7000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="error">
          {errorMessage}
        </Alert>
      </Snackbar>

      <div className={styles.user_management_container}>
        <div className={styles.user_list_container}>
          <div className={styles.add_user_container}>
            <div className={styles.edit_icon_container}>
              <Button
                className={styles.btn_add_user}
                onClick={() => handleCreateNewUser()}
              >
                <b className={styles.plus_sign}>+</b>Add New User
              </Button>
            </div>
          </div>
          <div className={styles.user_states_container}>
            <div className={styles.user_state_col}>
              <p>seats</p>
              <p>
                <b>{userReducer.users.seats || 0}</b>
              </p>
              <p>
                <b>booked</b>
              </p>
            </div>
            <div className={styles.user_state_col}>
              <p>users</p>
              <p>
                <b>{userReducer.users.active || 0}</b>
              </p>
              <p>
                <b>active</b>
              </p>
            </div>
            <div className={styles.user_state_col}>
              <p>
                <b>{userReducer.users.invitation_pending || 0}</b>
              </p>
              <p>
                <b>pending</b>
              </p>
            </div>
            <div className={styles.user_state_col}>
              <p>
                <b>{userReducer.users.deactivated || 0}</b>
              </p>
              <p>
                <b>deactivated</b>
              </p>
            </div>
          </div>

          {userReducer.users.list &&
            userReducer.users.list
              .filter(
                (user) => user.state === "ACTIVE" && user.role === "ADMIN"
              )
              .map((filteredUser) => (
                <UserRow user={filteredUser} key={filteredUser.user_id} />
              ))}

          {userReducer.users.list &&
            userReducer.users.list
              .filter(
                (user) => user.state === "ACTIVE" && user.role === "AGENT"
              )
              .map((filteredUser) => (
                <UserRow user={filteredUser} key={filteredUser.user_id} />
              ))}

          {userReducer.users.list &&
            userReducer.users.list
              .filter(
                (user) =>
                  user.state === "INVITATION_PENDING" && user.role === "ADMIN"
              )
              .map((filteredUser) => (
                <UserRow user={filteredUser} key={filteredUser.user_id} />
              ))}

          {userReducer.users.list &&
            userReducer.users.list
              .filter(
                (user) =>
                  user.state === "INVITATION_PENDING" && user.role === "AGENT"
              )
              .map((filteredUser) => (
                <UserRow user={filteredUser} key={filteredUser.user_id} />
              ))}

          {userReducer.users.list &&
            userReducer.users.list
              .filter(
                (user) => user.state === "DEACTIVATED" && user.role === "ADMIN"
              )
              .map((filteredUser) => (
                <UserRow user={filteredUser} key={filteredUser.user_id} />
              ))}

          {userReducer.users.list &&
            userReducer.users.list
              .filter(
                (user) => user.state === "DEACTIVATED" && user.role === "AGENT"
              )
              .map((filteredUser) => (
                <UserRow user={filteredUser} key={filteredUser.user_id} />
              ))}
        </div>
        <div className={styles.tabs_and_content_container}>
          {/* new user */}
          {userReducer.add_user_state ? <UserContent /> : null}

          {/* existing user */}
          {userReducer.selected_user &&
          Object.keys(userReducer.selected_user).length !== 0 ? (
            <UserContent />
          ) : null}

          {(userReducer.selected_user &&
            Object.keys(userReducer.selected_user).length === 0 &&
            !userReducer.add_user_state) ||
          (!userReducer.selected_user && !userReducer.add_user_state) ? (
            <div className={styles.user_no_data}>NO USER IS SELECTED!</div>
          ) : null}
        </div>
      </div>
      {displayUpgrade && (
        <UpgradePlanpopup
          title={title}
          subtitle={subtitle}
          open={displayUpgrade}
          imageUrl={UpgradeImage}
          upgradeButton={upgradeButton}
          copy_1={copy_1}
          copy_2={copy_2}
          close={() => setDisplayUpgrade(false)}
        />
      )}
    </div>
  );
}

export default UserManagement;
