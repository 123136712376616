import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { catchError, getUserDataFromJwt } from "@_utils/helpers";

import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import UpgradePlanpopup from "@_components/popups/UpgradePlanPopup/UpgradePlanPopup.js";
import UpgradeImage from "@_assets/images/add_email_flows.png";
import subscriptionCopy from "@_configs/subscription.config.js";
import styles from "./CaseTemplates.module.css";
import CaseTemplateRow from "../CaseTemplateRow/CaseTemplateRow";
import { caseTemplateActions } from "@_actions/caseTemplate.actions";
import { languageActions } from "@_actions/language.actions";
import CaseTemplateEditor from "@_components/CaseTemplateEditor/CaseTemplateEditor";
import { Button } from "@material-ui/core";
import SingleTextInputPopup from "@_components/popups/SingleTextInputPopup/SingleTextInputPopup";

function CaseTemplates() {
  const dispatch = useDispatch();

  const jwtData = getUserDataFromJwt();

  const caseTemplateReducer = useSelector((state) => state.caseTemplateReducer);
  const [newCaseTemplate, setNewCaseTemplate] = useState({
    name: "",
  });
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [displayUpgrade, setDisplayUpgrade] = useState(false);
  const [isAddTemplateDialogOpen, setIsAddTemplateDialogOpen] = useState(false);

  const { title, subtitle, copy_1, upgradeButton } =
    subscriptionCopy.emailFlow[jwtData.subscription.toLowerCase()];

  useEffect(() => {
    dispatch(languageActions.getTranslations());
  }, [dispatch]);

  const handleInputChange = (e) => {
    var value = e.target.value;
    setNewCaseTemplate((newTemplate) => ({
      ...newTemplate,
      name: value,
    }));
  };

  const handleSubmitInput = (e) => {
    e.preventDefault();
    if (newCaseTemplate.name) {
      dispatch(caseTemplateActions.selectCaseTemplate());
      dispatch(
        caseTemplateActions.addCaseTemplate({
          name: newCaseTemplate.name,
        })
      );
    }
    setNewCaseTemplate({ name: "" });
  };

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
    setOpenErrorSnackbar(false);
    setErrorMessage("");
    setSuccessMessage("");
  };

  const handleCloseAddTemplatePopup = () => {
    setNewCaseTemplate({
      name: "",
    });
    setIsAddTemplateDialogOpen(false);
  };

  useEffect(() => {
    dispatch(caseTemplateActions.getCaseTemplates());
  }, [dispatch]);

  // ERROR AND SUCCESS MESSAGES

  // display add template POST success & error
  useEffect(() => {
    if (caseTemplateReducer.status.CASETEMPLATE_ADD === "success") {
      setSuccessMessage("Success!");
      setOpenSnackbar(true);
      handleCloseAddTemplatePopup();
      dispatch(caseTemplateActions.getCaseTemplates());
      dispatch(caseTemplateActions.clearStatusMessage("CASETEMPLATE_ADD"));
    } else if (catchError(caseTemplateReducer.status.CASETEMPLATE_ADD)) {
      setErrorMessage(caseTemplateReducer.status.CASETEMPLATE_ADD);
      setOpenErrorSnackbar(true);
      dispatch(caseTemplateActions.clearStatusMessage("CASETEMPLATE_ADD"));
    } else return;
  }, [dispatch, caseTemplateReducer.status.CASETEMPLATE_ADD]);

  // display edit template  success & error
  useEffect(() => {
    if (caseTemplateReducer.status.CASETEMPLATE_UPDATE === "success") {
      setSuccessMessage("Success!");
      setOpenSnackbar(true);
      dispatch(caseTemplateActions.getCaseTemplates());
      dispatch(caseTemplateActions.clearStatusMessage("CASETEMPLATE_UPDATE"));
    } else if (catchError(caseTemplateReducer.status.CASETEMPLATE_UPDATE)) {
      setErrorMessage(caseTemplateReducer.status.CASETEMPLATE_UPDATE);
      setOpenErrorSnackbar(true);
      dispatch(caseTemplateActions.clearStatusMessage("CASETEMPLATE_UPDATE"));
    } else return;
  }, [dispatch, caseTemplateReducer.status.CASETEMPLATE_UPDATE]);

  // display email DELETE success & error
  // display edit template  success & error
  useEffect(() => {
    if (caseTemplateReducer.status.CASETEMPLATE_DELETE === "success") {
      setSuccessMessage("Success!");
      setOpenSnackbar(true);
      dispatch(caseTemplateActions.getCaseTemplates());
      dispatch(caseTemplateActions.clearStatusMessage("CASETEMPLATE_DELETE"));
    } else if (catchError(caseTemplateReducer.status.CASETEMPLATE_DELETE)) {
      setErrorMessage(caseTemplateReducer.status.CASETEMPLATE_DELETE);
      setOpenErrorSnackbar(true);
    } else return;
  }, [dispatch, caseTemplateReducer.status.CASETEMPLATE_DELETE]);

  return (
    <div className={styles.root}>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={4000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="success">
          {successMessage}
        </Alert>
      </Snackbar>
      {errorMessage && (
        <Snackbar
          open={openErrorSnackbar}
          autoHideDuration={7000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity="error">
            {errorMessage}
          </Alert>
        </Snackbar>
      )}

      <div className={styles.template_list_container}>
        <div className={styles.add_template_container}>
          <Button
            style={{ backgroundColor: "white" }}
            onClick={() => setIsAddTemplateDialogOpen(true)}
            variant="outlined"
          >
            Add Case Template
          </Button>
        </div>
        {caseTemplateReducer.caseTemplates &&
          caseTemplateReducer.caseTemplates.map((caseTemplate, index) => {
            return (
              <CaseTemplateRow
                data={caseTemplate}
                key={caseTemplate.id}
                index={index}
                styles={styles}
                name={caseTemplate.name}
                handleSubmitInput={handleSubmitInput}
                handleInputChange={handleInputChange}
              />
            );
          })}
      </div>

      {/* new template */}
      <div className={styles.template_container}>
        {/* existing template */}
        {caseTemplateReducer.selectedCaseTemplate &&
        Object.keys(caseTemplateReducer.selectedCaseTemplate).length !== 0 ? (
          <CaseTemplateEditor
            data={caseTemplateReducer.caseTemplates.find(
              (x) => x.id === caseTemplateReducer.selectedCaseTemplate
            )}
            key={caseTemplateReducer.selectedCaseTemplate}
          />
        ) : null}

        {(caseTemplateReducer.selectedCaseTemplate &&
          Object.keys(caseTemplateReducer.selectedCaseTemplate).length === 0 &&
          !caseTemplateReducer.add_template) ||
        (!caseTemplateReducer.selectedCaseTemplate &&
          !caseTemplateReducer.add_template) ? (
          <div className={styles.template_no_data}>
            NO TEMPLATE IS SELECTED!
          </div>
        ) : null}
      </div>
      {displayUpgrade && (
        <UpgradePlanpopup
          title={title}
          subtitle={subtitle}
          upgradeButton={upgradeButton}
          copy_1={copy_1}
          imageUrl={UpgradeImage}
          open={displayUpgrade}
          close={() => setDisplayUpgrade(false)}
        />
      )}

      <SingleTextInputPopup
        title={"Add Case Template"}
        open={isAddTemplateDialogOpen}
        onClose={handleCloseAddTemplatePopup}
        handleTextChange={handleInputChange}
        required={true}
        helperText={"Name must be set for the new template"}
        onSubmit={handleSubmitInput}
        placeholder={"Enter case template name"}
        submitButtonText={"Create"}
        textInputLabel={"Name"}
      ></SingleTextInputPopup>
    </div>
  );
}

export default CaseTemplates;
