const LanguageList = [
  { code: "en", language: "English" },
  { code: "de", language: "German" },
  { code: "pt", language: "Portuguese" },
  { code: "nl", language: "Dutch" },
  { code: "fi", language: "Finnish" },
  { code: "da", language: "Danish" },
  { code: "fr", language: "French" },
  { code: "es", language: "Spanish" },
  { code: "et", language: "Estonian" },
];

export default LanguageList;
