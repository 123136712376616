import styles from "./CaseTemplateEditor.module.css";
import React, { useEffect, useState } from "react";
import TextfieldInput from "@_components/TextfieldInput/TextfieldInput";
import { isNull } from "@_utils/helpers";
import { Button, FormControlLabel, Switch } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import CurrencyList from "@_configs/CurrencyList.config";
import CountryAutocompleteList from "@_configs/CountryAutocompleteList.config";
import TaxTypes from "@_configs/TaxType.config";
import { useDispatch, useSelector } from "react-redux";
import { caseTemplateActions } from "@_actions/caseTemplate.actions";
import { Clear } from "@material-ui/icons";
import { caseConstants } from "@_constants/case.constants";
import { isNaN } from "lodash";

function CaseTemplateEditor(props) {
  const currency = CurrencyList;
  const { data } = props;
  const [templateConfig, setTemplateConfig] = useState({
    additional_fees: data?.additional_fees ? data.additional_fees : [],
    country: data?.country,
    invoice_template_id: data?.invoice_template_id,
    vat_id_required: data?.vat_id_required,
    allow_prepayment_invoicing: data?.allow_prepayment_invoicing,
    created: data?.created,
    currency: data?.currency,
    customer_id: data?.customer_id,
    email_flow_id: data?.email_flow_id,
    id: data?.id,
    license_fee: data?.license_fee ? parseFloat(data?.license_fee) : null,
    name: data?.name,
    settlement_offer_num_days: data?.settlement_offer_num_days
      ? parseInt(data.settlement_offer_num_days)
      : null,
    settlement_offer_percentage: data?.settlement_offer_percentage
      ? parseFloat(data.settlement_offer_percentage)
      : null,
    tax_percentage: data?.tax_percentage
      ? parseFloat(data?.tax_percentage)
      : null,
    tax_type: data?.tax_type,
    translation_id: data?.translation_id,
  });

  const dispatch = useDispatch();
  const [trySave, setTrySave] = useState(false); // indecates if save was pressed already
  const emailFlowReducer = useSelector((state) => state.emailFlowReducer);
  const languageReducer = useSelector((state) => state.languageReducer);
  const invoiceReducer = useSelector((state) => state.invoiceTemplateReducer);
  const [currencyInput, setCurrencyInput] = useState();
  const [flowInput, setFlowInput] = useState();
  const [countryInput, setCountryInput] = useState();
  const [invoiceInput, setInvoiceInput] = useState();
  const [taxInput, setTaxInput] = useState();
  const [translationInput, setTranslationInput] = useState();
  const [feeError, setFeeError] = useState(-1);
  //TODO this must be really translation... then only activated ones
  const translations = languageReducer.translations.translations.filter(
    (x) => x.is_active === 1
  );
  const flows = emailFlowReducer.emailFlows;
  const countries = CountryAutocompleteList;
  const invoiceTemplates = invoiceReducer?.templates;

  useEffect(() => {
    setTemplateConfig({
      additional_fees: data?.additional_fees ? data.additional_fees : [],
      country: data?.country,
      invoice_template_id: data?.invoice_template_id,
      vat_id_required: data?.vat_id_required,
      allow_prepayment_invoicing: data?.allow_prepayment_invoicing,
      created: data?.created,
      currency: data?.currency,
      customer_id: data?.customer_id,
      email_flow_id: data?.email_flow_id,
      id: data?.id,
      license_fee: data?.license_fee ? parseFloat(data?.license_fee) : null,
      name: data?.name,
      settlement_offer_num_days: data?.settlement_offer_num_days
        ? parseInt(data.settlement_offer_num_days)
        : null,
      settlement_offer_percentage: data?.settlement_offer_percentage
        ? parseFloat(data.settlement_offer_percentage)
        : null,
      tax_percentage: data?.tax_percentage
        ? parseFloat(data?.tax_percentage)
        : null,
      tax_type: data?.tax_type,
      translation_id: data?.translation_id,
    });
  }, [data]);

  useEffect(() => {
    if (templateConfig.currency)
      setCurrencyInput(
        currency.find((x) => x.id === templateConfig.currency).label
      );
    if (templateConfig.tax_type) {
      setTaxInput(TaxTypes.find((x) => x.id === templateConfig.tax_type).label);
    }
    if (templateConfig.translation_id)
      setTranslationInput(
        translations.find((x) => x.id === templateConfig.translation_id)
      );
    if (templateConfig.country)
      setCountryInput(countries.find((x) => x.id === templateConfig.country));
    if (templateConfig.email_flow_id)
      setFlowInput(flows.find((x) => x.id === templateConfig.email_flow_id));
    if (templateConfig.invoice_template_id) {
      setInvoiceInput(
        invoiceTemplates.find(
          (x) => x.id === templateConfig.invoice_template_id
        ).name
      );
    }
  }, [
    countries,
    invoiceTemplates,
    currency,
    flows,
    templateConfig.country,
    templateConfig.currency,
    templateConfig.email_flow_id,
    templateConfig.translation_id,
    templateConfig.tax_type,
    templateConfig.invoice_template_id,
    translations,
  ]);

  const handleSave = () => {
    // check data and dispatch
    setTrySave(true);
    let canSave = true;

    // check if additional fees are uique:
    for (const fee of templateConfig.additional_fees) {
      if (
        templateConfig.additional_fees.filter((x) => x.name === fee.name)
          .length > 1
      ) {
        canSave = false;
        setFeeError(
          templateConfig.additional_fees.lastIndexOf(
            templateConfig.additional_fees.filter((x) => x.name === fee.name)[1]
          )
        );
      }

      if (!fee.name || fee.name.trim === "") canSave = false;
      if (isNaN(fee.percentage) || fee.percentage <= 0) canSave = false;
    }

    // check name for null
    if (!templateConfig.name || templateConfig.name.trim() === "")
      canSave = false;

    if (
      templateConfig.tax_type === caseConstants.CASE_TAX_TYPE_NO_INDIRECT_TAX ||
      templateConfig.tax_type === caseConstants.CASE_TAX_TYPE_REVERSE_CHARGE
    ) {
      delete templateConfig.tax_percentage;
    }

    if (
      !isNull(templateConfig.tax_type) &&
      templateConfig.tax_type !== caseConstants.CASE_TAX_TYPE_NO_INDIRECT_TAX &&
      templateConfig.tax_type !== caseConstants.CASE_TAX_TYPE_REVERSE_CHARGE &&
      (isNull(templateConfig.tax_percentage) ||
        isNaN(templateConfig.tax_percentage) ||
        templateConfig.tax_percentage <= 0)
    ) {
      canSave = false;
      alert("VAT Percentage must be larger than 0");
      return;
    }

    if (canSave) {
      // take action
      if (templateConfig.id === "new")
        dispatch(caseTemplateActions.addCaseTemplate(templateConfig));
      else dispatch(caseTemplateActions.updateCaseTemplate(templateConfig));
    }
  };

  const resetTrySave = () => {
    setTrySave(false);
    setFeeError(-1);
  };

  const handleConfigChange = (event, field, isNumber) => {
    resetTrySave();
    if (isNumber)
      setTemplateConfig({
        ...templateConfig,
        [field]: parseFloat(event.target.value),
      });
    else
      setTemplateConfig({
        ...templateConfig,
        [field]: event.target.value,
      });

    if (
      field === "settlement_offer_percentage" &&
      parseFloat(event.target.value) > 100
    ) {
      setTemplateConfig({
        ...templateConfig,
        [field]: 100,
      });
    }
  };

  const handleSwitchChange = (event, field) => {
    resetTrySave();
    setTemplateConfig({
      ...templateConfig,
      [field]: event.target.checked,
    });
  };

  const handleAutoCompleteChange = (id, field) => {
    resetTrySave();
    console.log(field, id);
    setTemplateConfig((prevConfig) => {
      const updatedConfig = { ...prevConfig, [field]: id };
      return updatedConfig;
    });
  };

  const addFeeRow = () => {
    resetTrySave();
    setTemplateConfig((prev) => ({
      ...prev,
      additional_fees: [
        ...prev.additional_fees,
        { name: null, percentage: 0, taxable: false },
      ],
    }));
  };

  const handleFeeNameChanged = (index, event) => {
    resetTrySave();

    setTemplateConfig({
      ...templateConfig,
      additional_fees: templateConfig.additional_fees.map((fee, i) => {
        if (i === index)
          return {
            name: event.target.value,
            percentage: fee.percentage,
            taxable: fee.taxable,
          };
        return fee;
      }),
    });
  };

  const handleFeePercentageChanged = (index, event) => {
    resetTrySave();

    setTemplateConfig({
      ...templateConfig,
      additional_fees: templateConfig.additional_fees.map((fee, i) => {
        if (i === index)
          return {
            name: fee.name,
            percentage: Number.parseFloat(event.target.value),
            taxable: fee.taxable,
          };
        return fee;
      }),
    });
  };

  const removeAdditionalFee = (index) => {
    var newArray = [...templateConfig.additional_fees];
    newArray.splice(index, 1);
    setTemplateConfig({
      ...templateConfig,
      additional_fees: newArray,
    });
  };

  const renderAdditionalFees = () => {
    var rows = [];
    for (
      let index = 0;
      index < templateConfig.additional_fees.length;
      index++
    ) {
      rows.push(
        <div className={styles.feeRow}>
          <TextfieldInput
            className={styles.feeRowTextInput}
            label="Fee Name"
            placeholder="Fee Name"
            onChange={(event) => handleFeeNameChanged(index, event)}
            name={"fee_name_" + index}
            value={templateConfig.additional_fees[index].name}
            required
            helperText={feeError === index ? "Name already in use" : null}
            error={
              trySave &&
              (isNull(templateConfig.additional_fees[index].name) ||
                feeError === index)
            }
          />
          <TextfieldInput
            className={styles.feeRowTextInput}
            label="Fee Percentage"
            placeholder="Fee Percentage"
            onChange={(event) => handleFeePercentageChanged(index, event)}
            name={"fee_name_" + index}
            value={templateConfig.additional_fees[index].percentage}
            required
            type={"number"}
            error={
              trySave &&
              (isNull(templateConfig.additional_fees[index].percentage) ||
                isNaN(templateConfig.additional_fees[index].percentage) ||
                templateConfig.additional_fees[index].percentage <= 0)
            }
          />
          <Clear
            style={{ marginRight: "10px" }}
            onClick={() => removeAdditionalFee(index)}
          ></Clear>
        </div>
      );
    }
    return rows;
  };

  return (
    <div className={styles.root}>
      <div className={styles.confirm_btn_container}>
        <div className={styles.template_name_container}>
          <TextfieldInput
            label="Template Name"
            onChange={(event) => handleConfigChange(event, "name")}
            name="name"
            value={templateConfig.name || ""}
            required
            error={trySave && isNull(templateConfig.name)}
          />
        </div>
        <Button className={styles.confirm_btn} onClick={handleSave}>
          Save
        </Button>
      </div>
      <h2 className={styles.mt40}>Licensing Offer Defaults</h2>
      <div className={styles.licenseOfferContainer}>
        <div className={styles.currencyRow}>
          <div>
            <TextfieldInput
              label="Licence Fee"
              placeholder="Licence Fee"
              onChange={(event) =>
                handleConfigChange(event, "license_fee", true)
              }
              name="license_feesettlement_offer_percentage"
              value={templateConfig.license_fee}
              type={"number"}
            />
          </div>
        </div>
        <div className={styles.currencyRow}>
          <div>
            <Autocomplete
              id="combo-box-demo"
              options={CurrencyList}
              inputValue={currencyInput}
              value={
                CurrencyList.find((x) => x.id === templateConfig.currency)
                  ? CurrencyList.find((x) => x.id === templateConfig.currency)
                  : null
              }
              getOptionLabel={(option) => option.label}
              onInputChange={(e) => {
                if (e) setCurrencyInput(e.target.value);
              }}
              onChange={(event, option) => {
                if (option) handleAutoCompleteChange(option.id, "currency");
                else handleAutoCompleteChange(null, "currency");
              }}
              renderInput={(params) => (
                <TextfieldInput {...params} label="Currency" />
              )}
            />
          </div>
        </div>
        <div className={styles.currencyRow}>
          <div>
            <Autocomplete
              id="combo-box-demo"
              options={CountryAutocompleteList}
              inputValue={countryInput}
              value={
                CountryAutocompleteList.find(
                  (x) => x.id === templateConfig.country
                )
                  ? CountryAutocompleteList.find(
                      (x) => x.id === templateConfig.country
                    )
                  : null
              }
              getOptionLabel={(option) => option.label}
              onChange={(event, option) => {
                if (option) handleAutoCompleteChange(option.id, "country");
                else handleAutoCompleteChange(null, "country");
              }}
              onInputChange={(e) => {
                if (e) setCountryInput(e.target.value);
              }}
              renderInput={(params) => (
                <TextfieldInput {...params} label="Country" />
              )}
            />
          </div>
        </div>
        <div className={styles.currencyRow}>
          <div>
            <Autocomplete
              id="combo-box-demo"
              options={TaxTypes}
              inputValue={taxInput}
              value={
                TaxTypes.find((x) => x.id === templateConfig.tax_type)
                  ? TaxTypes.find((x) => x.id === templateConfig.tax_type)
                  : ""
              }
              getOptionLabel={(option) => option.label}
              onChange={(event, option) => {
                if (option) handleAutoCompleteChange(option.id, "tax_type");
                else handleAutoCompleteChange(null, "tax_type");
                handleAutoCompleteChange(null, "invoice_template_id");
              }}
              onInputChange={(e) => {
                if (e) {
                  setTaxInput(e.target.value);
                  setInvoiceInput("");
                }
              }}
              renderInput={(params) => (
                <TextfieldInput {...params} label="Tax Type" />
              )}
            />
          </div>
        </div>
        {templateConfig.tax_type &&
          templateConfig.tax_type !==
            caseConstants.CASE_TAX_TYPE_NO_INDIRECT_TAX &&
          templateConfig.tax_type !==
            caseConstants.CASE_TAX_TYPE_REVERSE_CHARGE && (
            <div className={styles.currencyRow}>
              <div>
                <TextfieldInput
                  label="Tax Percentage"
                  placeholder="Tax Percentage"
                  onChange={(event) =>
                    handleConfigChange(event, "tax_percentage", true)
                  }
                  name="tax_percentage"
                  value={templateConfig.tax_percentage}
                  type={"number"}
                  error={
                    (trySave && isNull(templateConfig.tax_percentage)) ||
                    (trySave && isNaN(templateConfig.tax_percentage)) ||
                    (trySave && templateConfig.tax_percentage <= 0)
                  }
                />
              </div>
            </div>
          )}
        <div className={styles.currencyRow}>
          <div>
            <Autocomplete
              disabled={!templateConfig.tax_type}
              id="combo-box-demo"
              options={invoiceTemplates.filter(
                (invoice) => invoice.tax_type === templateConfig.tax_type
              )}
              inputValue={invoiceInput}
              value={
                invoiceTemplates.find(
                  (x) => x.id === templateConfig.invoice_template_id
                )
                  ? invoiceTemplates.find(
                      (x) => x.id === templateConfig.invoice_template_id
                    )
                  : null
              }
              getOptionLabel={(option) => option.name}
              onChange={(event, option) => {
                if (option)
                  handleAutoCompleteChange(option.id, "invoice_template_id");
                else handleAutoCompleteChange(null, "invoice_template_id");
              }}
              onInputChange={(e) => {
                if (e) setInvoiceInput(e.target.value);
              }}
              renderInput={(params) => (
                <TextfieldInput {...params} label="Invoice template" />
              )}
            />
          </div>
        </div>
        <div className={styles.currencyRow}>
          <FormControlLabel
            required
            control={
              <Switch
                color="primary"
                className={styles.taxable_switch}
                checked={templateConfig.vat_id_required}
                onChange={(event) =>
                  handleSwitchChange(event, "vat_id_required")
                }
                id={"vat_id_required"}
                name="vat_id_required"
                inputProps={{ "aria-label": "primary checkbox" }}
              />
            }
            label="Website owner's VAT ID required"
          />
        </div>
        <div className={styles.currencyRow}>
          <FormControlLabel
            required
            control={
              <Switch
                color="primary"
                className={styles.taxable_switch}
                checked={templateConfig.allow_prepayment_invoicing}
                onChange={(event) =>
                  handleSwitchChange(event, "allow_prepayment_invoicing")
                }
                id={"allow_prepayment_invoicing"}
                name="allow_prepayment_invoicing"
                inputProps={{ "aria-label": "primary checkbox" }}
              />
            }
            label="allow invoice generation before payment"
          />
        </div>
      </div>
      <h2 className={styles.mt40}>Additional Fee Defaults</h2>
      <div className={styles.currencyRow}>
        <div className={styles.currencyRow}>
          <button onClick={addFeeRow}>Add Additional Fee</button>
        </div>
        {renderAdditionalFees()}
      </div>
      <h2 className={styles.mt40}>Settlement Offer Defaults</h2>
      <div className={styles.settlementOfferContainer}>
        <div className={styles.currencyRow}>
          <div>
            <TextfieldInput
              label="Settlement Offer Percentage"
              placeholder="Percentage"
              onChange={(event) =>
                handleConfigChange(event, "settlement_offer_percentage", true)
              }
              name="settlement_offer_percentage"
              value={templateConfig.settlement_offer_percentage}
              type={"number"}
              error={
                trySave &&
                templateConfig.settlement_offer_num_days &&
                isNull(templateConfig.settlement_offer_percentage)
              }
            />
          </div>
        </div>
        <div className={styles.currencyRow}>
          <div>
            <TextfieldInput
              label="Settlement Offer Validity Period (in days)"
              placeholder="Number of Days"
              onChange={(event) =>
                handleConfigChange(event, "settlement_offer_num_days", true)
              }
              name="settlement_offer_num_days"
              type={"number"}
              inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
              value={templateConfig.settlement_offer_num_days}
              error={
                trySave &&
                templateConfig.settlement_offer_percentage &&
                isNull(templateConfig.settlement_offer_num_days)
              }
            />
          </div>
        </div>
      </div>
      <h2 className={styles.mt40}>Other Defaults</h2>
      <div classNmae={styles.portalContainer}>
        <div className={styles.currencyRow}>
          <div>
            <Autocomplete
              id="combo-box-demo"
              options={flows}
              inputValue={flowInput}
              value={
                flows.find((x) => x.id === templateConfig.email_flow_id)
                  ? flows.find((x) => x.id === templateConfig.email_flow_id)
                  : null
              }
              getOptionLabel={(option) => option.name}
              onChange={(event, option) => {
                if (option)
                  handleAutoCompleteChange(option.id, "email_flow_id");
                else handleAutoCompleteChange(null, "email_flow_id");
              }}
              onInputChange={(e) => {
                if (e) setFlowInput(e.target.value);
              }}
              renderInput={(params) => (
                <TextfieldInput {...params} label="Email Flow" />
              )}
            />
          </div>
        </div>
        <div className={styles.currencyRow}>
          <div>
            <Autocomplete
              id="combo-box-demo"
              options={translations}
              inputValue={translationInput}
              value={
                translations.find((x) => x.id === templateConfig.translation_id)
                  ? translations.find(
                      (x) => x.id === templateConfig.translation_id
                    )
                  : null
              }
              getOptionLabel={(option) => option.language}
              onChange={(event, option) => {
                if (option)
                  handleAutoCompleteChange(option.id, "translation_id");
                else handleAutoCompleteChange(null, "translation_id");
              }}
              onInputChange={(e) => {
                if (e) setTranslationInput(e.target.value);
              }}
              renderInput={(params) => (
                <TextfieldInput {...params} label="Infringer Portal Language" />
              )}
            />
          </div>
        </div>
      </div>
      <div className={styles.confirm_btn_container_bottom}>
        <div className={styles.template_name_container}>&nbsp;</div>
        <Button className={styles.confirm_btn} onClick={handleSave}>
          Save
        </Button>
      </div>
    </div>
  );
}

export default CaseTemplateEditor;
