import React, { useState, useEffect, useMemo } from "react";
import styles from "./InvoiceManagement.module.css";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { Clear, FileCopyOutlined, MoreHoriz } from "@material-ui/icons";
import {
  Button,
  CircularProgress,
  ClickAwayListener,
  IconButton,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Snackbar,
} from "@material-ui/core";
import TextfieldInput from "@_components/TextfieldInput/TextfieldInput";
import { invoiceTemplateActions } from "@_actions/invoiceTemplate.actions";
import { useDispatch, useSelector } from "react-redux";
import { catchError } from "@_utils/helpers";
import { Alert } from "@material-ui/lab";
import { invoiceTemplateConstants } from "@_constants/invoiceTemplate.constants";
import { caseActivityActions } from "@_actions/caseActivity.actions";

function InvoiceManagement() {
  const dispatch = useDispatch();
  const invoiceTemplateReducer = useSelector(
    (state) => state.invoiceTemplateReducer
  );
  const caseActivityReducer = useSelector((state) => state.caseActivityReducer);
  const [openInvoiceMenu, setOpenInvoiceMenu] = useState({});
  const [invoices, setInvoices] = useState([]);
  const [filter, setFilter] = useState({
    to: "",
    from: "",
    type: "",
    status: "",
    search: "",
  });
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const refsById = useMemo(() => {
    const refs = {};
    invoices.forEach((invoice) => {
      refs[invoice.number] = React.createRef(null);
    });
    return refs;
  }, [invoices]);

  useEffect(() => {
    let sentFilter = filter;
    if (!filter.to || filter.to?.trim().length === 0) {
      delete sentFilter.to;
    }
    if (!filter.from) {
      delete sentFilter.from;
    }

    if (!filter.type || filter.type?.trim().length === 0) {
      delete sentFilter.type;
    }
    if (!filter.status || filter.status?.trim().length === 0) {
      delete sentFilter.status;
    }

    if (!filter.search || filter.search?.trim().length === 0) {
      delete sentFilter.search;
    }
    setFilter(sentFilter);
    dispatch(invoiceTemplateActions.getInvoicesList(sentFilter));
  }, [filter, dispatch]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
    setOpenErrorSnackbar(false);
    setErrorMessage("");
  };

  useEffect(() => {
    if (invoiceTemplateReducer.status.INVOICES_LIST_GET === "request") {
      setLoading(true);
    }
    if (invoiceTemplateReducer.status.INVOICES_LIST_GET === "success") {
      setInvoices(invoiceTemplateReducer.invoiceList);
      setLoading(false);
      dispatch(invoiceTemplateActions.clearStatusMessage("INVOICES_LIST_GET"));
    }
    if (catchError(invoiceTemplateReducer.status.INVOICES_LIST_GET)) {
      setLoading(false);
      setErrorMessage(invoiceTemplateReducer.status.INVOICES_LIST_GET);
      setOpenErrorSnackbar(true);
      dispatch(invoiceTemplateActions.clearStatusMessage("INVOICES_LIST_GET"));
    }
  }, [
    dispatch,
    invoiceTemplateReducer.status.INVOICES_LIST_GET,
    invoiceTemplateReducer.invoiceList,
  ]);

  useEffect(() => {
    if (invoiceTemplateReducer.status.INVOICES_LIST_DOWNLOAD === "request") {
      setLoading(true);
    }
    if (invoiceTemplateReducer.status.INVOICES_LIST_DOWNLOAD === "success") {
      setLoading(false);
      setOpenSnackbar(true);
      dispatch(
        invoiceTemplateActions.clearStatusMessage("INVOICES_LIST_DOWNLOAD")
      );
    }
    if (catchError(invoiceTemplateReducer.status.INVOICES_LIST_DOWNLOAD)) {
      setLoading(false);
      setErrorMessage(invoiceTemplateReducer.status.INVOICES_LIST_DOWNLOAD);
      setOpenErrorSnackbar(true);
      dispatch(
        invoiceTemplateActions.clearStatusMessage("INVOICES_LIST_DOWNLOAD")
      );
    }
  }, [dispatch, invoiceTemplateReducer.status.INVOICES_LIST_DOWNLOAD]);

  useEffect(() => {
    if (caseActivityReducer.status.INVOICE_CANCEL === "request") {
      setLoading(true);
    }
    if (caseActivityReducer.status.INVOICE_CANCEL === "success") {
      setLoading(false);
      setOpenSnackbar(true);
      dispatch(caseActivityActions.clearState("INVOICE_CANCEL"));
      dispatch(invoiceTemplateActions.getInvoicesList(filter));
    }
    if (catchError(caseActivityReducer.status.INVOICE_CANCEL)) {
      setLoading(false);
      setErrorMessage(caseActivityReducer.status.INVOICE_CANCEL);
      setOpenErrorSnackbar(true);
      dispatch(caseActivityActions.clearState("INVOICE_CANCEL"));
    }
    // eslint-disable-next-line
  }, [dispatch, caseActivityReducer.status.INVOICE_CANCEL]);

  const handleOpenInvoiceMenu = (event, invoiceNumber) => {
    event.stopPropagation();
    setOpenInvoiceMenu((prev) => {
      return { ...prev, [invoiceNumber]: true };
    });
  };

  const handleFilterChange = (event) => {
    event.persist();
    setFilter((prevFilter) => {
      return {
        ...prevFilter,
        [event.target?.name]: event.target?.value,
      };
    });
  };

  const handleDownloadInvoices = () => {
    dispatch(invoiceTemplateActions.downloadInvoicesList(filter));
  };

  const handleDownloadInvoice = (caseId, invoiceId, invoiceNumber, action) => {
    dispatch(
      invoiceTemplateActions.downloadInvoice(
        caseId,
        invoiceId,
        invoiceNumber,
        action
      )
    );
  };

  const handleCancelInvoice = (caseId, invoiceId) => {
    dispatch(invoiceTemplateActions.cancelInvoice(caseId, invoiceId));
  };
  const handleClearClick = () => {
    setFilter((prevFilter) => {
      return {
        ...prevFilter,
        search: "",
      };
    });
  };
  return (
    <div className={styles.root}>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={2000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="success">
          Success
        </Alert>
      </Snackbar>
      {errorMessage && (
        <Snackbar
          open={openErrorSnackbar}
          autoHideDuration={2000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity="error">
            {errorMessage}
          </Alert>
        </Snackbar>
      )}
      <div className={styles.title}>
        <FileCopyOutlined className={styles.icon} />
        <h2>Accounting </h2>
        <ArrowForwardIosIcon fontSize="small" className={styles.arrow_icon} />
        <h2>Invoices</h2>
        {loading && (
          <CircularProgress className={styles.loading_title} color="primary" />
        )}
      </div>
      <div className={styles.filter_row}>
        <TextfieldInput
          label="From"
          type="date"
          name="from"
          value={filter.from}
          onChange={handleFilterChange}
          className={styles.date_picker}
        />
        <TextfieldInput
          label="To"
          type="date"
          name="to"
          value={filter.to}
          onChange={handleFilterChange}
          className={styles.date_picker}
        />
        <TextfieldInput
          select
          label="Type"
          name="type"
          value={filter.type || " "}
          onChange={handleFilterChange}
          className={styles.select}
        >
          <MenuItem value=" ">All</MenuItem>
          <MenuItem value={"INVOICE"}>Invoice</MenuItem>
          <MenuItem value={"CANCELLATION"}>Cancellation invoice</MenuItem>
        </TextfieldInput>
        <TextfieldInput
          select
          label="Status"
          name="status"
          value={filter.status || " "}
          onChange={handleFilterChange}
          className={styles.select}
        >
          <MenuItem value={" "}>All</MenuItem>
          <MenuItem value={"OPEN"}>Open</MenuItem>
          <MenuItem value={"PAID"}>Paid</MenuItem>
          <MenuItem value={"CANCELLED"}>Cancelled</MenuItem>
        </TextfieldInput>
        <TextfieldInput
          label="Invoice/Case number"
          name="search"
          value={filter.search}
          onChange={handleFilterChange}
          InputProps={
            filter.search
              ? {
                  endAdornment: (
                    <IconButton
                      sx={{
                        visibility: filter.search ? "visible" : "hidden",
                      }}
                      onClick={handleClearClick}
                      style={{ width: 25, height: 25 }}
                    >
                      <Clear />
                    </IconButton>
                  ),
                }
              : {}
          }
          className={styles.text_field}
        />
        <Button
          onClick={handleDownloadInvoices}
          variant="outlined"
          className={styles.download_button}
          disabled={loading || invoices?.length === 0}
        >
          Download {invoices?.length}{" "}
          {` ${invoices?.length === 1 ? "invoice" : "invoices"}`}
        </Button>
      </div>
      <div className={styles.invoices_list}>
        <div className={styles.headers_column}>
          <p style={{ width: "8%" }}>Date</p>
          <p style={{ width: "10%" }}>Invoice number</p>
          <p style={{ width: "12%" }}>Type</p>
          <p style={{ width: "8%" }}>Status</p>
          <p style={{ width: "8%" }}>Case ID</p>
          <p style={{ textAlign: "end", width: "10%" }}>Amount</p>
          <p style={{ width: "6%" }}></p>
        </div>
        {invoices?.map((invoice) => (
          <div key={invoice.id} className={styles.invoice_column}>
            <p style={{ width: "8%" }}>
              {new Date(invoice.created).toLocaleDateString()}
            </p>
            <p style={{ width: "10%" }}>{invoice.number}</p>
            <p style={{ width: "12%" }}>
              {invoice.type ===
              invoiceTemplateConstants.INVOICE_TYPE_CANCELLATION
                ? "Cancellation Invoice"
                : "Invoice"}
            </p>
            <p style={{ width: "8%" }}>{invoice.status}</p>
            <p style={{ width: "8%" }}>{invoice.case_number}</p>
            <p style={{ textAlign: "end", width: "10%" }}>
              {invoice.data.final_amount_gross} {invoice.data.currency}
            </p>
            <MoreHoriz
              className={styles.more_icon}
              onClick={(event) => handleOpenInvoiceMenu(event, invoice.number)}
              ref={refsById[invoice.number]}
              aria-controls={
                openInvoiceMenu[invoice.number]
                  ? `menu-list-grow${invoice.number}`
                  : undefined
              }
              aria-haspopup="true"
            />
            <Popper
              open={openInvoiceMenu[invoice.number] || false}
              anchorEl={refsById[invoice.number].current}
              role={undefined}
              transition
              disablePortal
              className={styles.more_container}
            >
              <Paper>
                <ClickAwayListener
                  onClickAway={() => setOpenInvoiceMenu(false)}
                >
                  <MenuList
                    autoFocusItem={openInvoiceMenu[invoice.number]}
                    id={`menu-list-grow${invoice.number}`}
                  >
                    <MenuItem
                      onClick={() =>
                        handleDownloadInvoice(
                          invoice.case_id,
                          invoice.id,
                          invoice.number,
                          "view"
                        )
                      }
                    >
                      View
                    </MenuItem>
                    <MenuItem
                      onClick={() =>
                        handleDownloadInvoice(
                          invoice.case_id,
                          invoice.id,
                          invoice.number,
                          "download"
                        )
                      }
                    >
                      Download
                    </MenuItem>
                    {invoice.status === "OPEN" && (
                      <MenuItem
                        onClick={() =>
                          handleCancelInvoice(invoice.case_id, invoice.id)
                        }
                      >
                        Cancel
                      </MenuItem>
                    )}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Popper>
          </div>
        ))}
      </div>
    </div>
  );
}

export default InvoiceManagement;
