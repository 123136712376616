import { sidebarConstants } from "@_constants/sidebar.constants";

export const sidebarActions = {
    updateStatus,
    initNumbers,
    updateSelections,
};

function updateStatus(status) {
    return { type: sidebarConstants.UPDATE_STATUS, status };
}

function initNumbers(numbers) {
    return { type: sidebarConstants.INIT_NUMBERS, numbers };
}

function updateSelections(id) {
    return { type: sidebarConstants.UPDATE_SELECTIONS, id };
}

  
  