export const userConstants = {
  USER_GET_REQUEST: "USER_GET_REQUEST",
  USER_GET_SUCCESS: "USER_GET_SUCCESS",
  USER_GET_FAILURE: "USER_GET_FAILURE",

  USER_POST_REQUEST: "USER_POST_REQUEST",
  USER_POST_SUCCESS: "USER_POST_SUCCESS",
  USER_POST_FAILURE: "USER_POST_FAILURE",

  USER_UPDATE_REQUEST: "USER_UPDATE_REQUEST",
  USER_UPDATE_SUCCESS: "USER_UPDATE_SUCCESS",
  USER_UPDATE_FAILURE: "USER_UPDATE_FAILURE",

  USER_DELETE_REQUEST: "USER_DELETE_REQUEST",
  USER_DELETE_SUCCESS: "USER_DELETE_SUCCESS",
  USER_DELETE_FAILURE: "USER_DELETE_FAILURE",

  USER_ACTIVATE_REQUEST: "USER_ACTIVATE_REQUEST",
  USER_ACTIVATE_SUCCESS: "USER_ACTIVATE_SUCCESS",
  USER_ACTIVATE_FAILURE: "USER_ACTIVATE_FAILURE",

  USER_DEACTIVATE_REQUEST: "USER_DEACTIVATE_REQUEST",
  USER_DEACTIVATE_SUCCESS: "USER_DEACTIVATE_SUCCESS",
  USER_DEACTIVATE_FAILURE: "USER_DEACTIVATE_FAILURE",

  USER_GET_SIGNUP_REQUEST: "USER_GET_SIGNUP_REQUEST",
  USER_GET_SIGNUP_SUCCESS: "USER_GET_SIGNUP_SUCCESS",
  USER_GET_SIGNUP_FAILURE: "USER_GET_SIGNUP_FAILURE",

  USER_PUT_SIGNUP_REQUEST: "USER_PUT_SIGNUP_REQUEST",
  USER_PUT_SIGNUP_SUCCESS: "USER_PUT_SIGNUP_SUCCESS",
  USER_PUT_SIGNUP_FAILURE: "USER_PUT_SIGNUP_FAILURE",

  SELECT_USER: "SELECT_USER",

  ADD_USER: "ADD_USER",

  // clear status
  USER_CLEAR_STATUS_MESSAGE: "USER_CLEAR_STATUS_MESSAGE",

  // reset Name
  USER_RESET_NAME_REQUES: "USER_RESET_NAME_REQUES",
  USER_RESET_NAME_SUCCESS: "USER_RESET_NAME_SUCCESS",
  USER_RESET_NAME_FAILURE: "USER_RESET_NAME_FAILURE",

  // reset Password
  USER_RESET_PASSWORD_REQUES: "USER_RESET_PASSWORD_REQUES",
  USER_RESET_PASSWORD_SUCCESS: "USER_RESET_PASSWORD_SUCCESS",
  USER_RESET_PASSWORD_FAILURE: "USER_RESET_PASSWORD_FAILURE",

  // forgot password?
  USER_PUT_PASSWORD_LINK_REQUEST: "USER_PUT_PASSWORD_LINK_REQUEST",
  USER_PUT_PASSWORD_LINK_SUCCESS: "USER_PUT_PASSWORD_LINK_SUCCESS",
  USER_PUT_PASSWORD_LINK_FAILURE: "USER_PUT_PASSWORD_LINK_FAILURE",

  USER_GET_PASSWORD_LINK_REQUEST: "USER_GET_PASSWORD_LINK_REQUEST",
  USER_GET_PASSWORD_LINK_SUCCESS: "USER_GET_PASSWORD_LINK_SUCCESS",
  USER_GET_PASSWORD_LINK_FAILURE: "USER_GET_PASSWORD_LINK_FAILURE",

  USER_PUT_NEW_PASSWORD_REQUEST: "USER_PUT_NEW_PASSWORD_REQUEST",
  USER_PUT_NEW_PASSWORD_SUCCESS: "USER_PUT_NEW_PASSWORD_SUCCESS",
  USER_PUT_NEW_PASSWORD_FAILURE: "USER_PUT_NEW_PASSWORD_FAILURE",

  //Signature
  USER_GET_SIGNATURE_REQUEST: "USER_GET_SIGNATURE_REQUEST",
  USER_GET_SIGNATURE_SUCCESS: "USER_GET_SIGNATURE_SUCCESS",
  USER_GET_SIGNATURE_FAILURE: "USER_GET_SIGNATURE_FAILURE",

  USER_PUT_NEW_SIGNATURE_REQUEST: "USER_PUT_SIGNATURE_REQUEST",
  USER_PUT_NEW_SIGNATURE_SUCCESS: "USER_PUT_SIGNATURE_SUCCESS",
  USER_PUT_NEW_SIGNATURE_FAILURE: "USER_PUT_SIGNATURE_FAILURE",

  // get current onboarding status
  USER_GET_ONBOARDING_STATUS_REQUEST: "USER_GET_ONBOARDING_STATUS_REQUEST",
  USER_GET_ONBOARDING_STATUS_SUCCESS: "USER_GET_ONBOARDING_STATUS_SUCCESS",
  USER_GET_ONBOARDING_STATUS_FAILURE: "USER_GET_ONBOARDING_STATUS_FAILURE",

  USER_SUBSCRIPTION_LITE: "LITE",
  USER_SUBSCRIPTION_PRO: "PRO",
  USER_SUBSCRIPTION_ENTERPRISE: "ENTERPRISE",
};
