import React, { useEffect, useState } from "react";
import styles from "./EmailFlowTemplate.module.css";
import { Button } from "@material-ui/core";
import ClickableTextField from "@_components/ClickableTextField/ClickableTextField";
import { emailConstants } from "@_constants/email_flows.constants";
import { getUserDataFromJwt } from "@_utils/helpers";
import { userConstants } from "@_constants/user.constants";
import subscriptionCopy from "@_configs/subscription.config";
import UpgradePlanpopup from "@_components/popups/UpgradePlanPopup/UpgradePlanPopup";
import UpgradeImage from "@_assets/images/add_email_flows.png";

const EmailFlowTemplate = ({
  handleSelectTemplate,
  handleAddTemplate,
  email,
  customMails,
  initialMailTemplate,
  licenseValidatedTemplate,
  licenseRejectedTemplate,
  paymentRecievedTemplate,
  discountEndTemplate,
  scheduledMails,
}) => {
  let userData = getUserDataFromJwt();
  const [isLite, setIsLite] = useState(false);
  const [displayUpgrade, setDisplayUpgrade] = useState(false);
  const { title, subtitle, copy_1, upgradeButton } =
    subscriptionCopy.emailFlow[userData.subscription.toLowerCase()];

  useEffect(() => {
    userData.subscription === userConstants.USER_SUBSCRIPTION_LITE
      ? setIsLite(true)
      : setIsLite(false);
  }, [userData.subscription]);

  const handleClick = (template, type, trigger) => {
    isLite &&
    (type === emailConstants.TEMPLATE_TYPE_SCHEDULED ||
      (type === emailConstants.TEMPLATE_TYPE_TRIGGERED &&
        trigger !== emailConstants.TRIGGER_EVENT_INITIAL))
      ? showUpgradePopup()
      : template
      ? handleSelectTemplate(template)
      : handleAddTemplate(email.id, type, trigger);
  };

  const showUpgradePopup = () => {
    setDisplayUpgrade(true);
  };

  return (
    <div className={styles.flow_template_container}>
      <ClickableTextField
        label="Initial email"
        variant="outlined"
        size="small"
        fullWidth={true}
        value={initialMailTemplate ? initialMailTemplate.name : ""}
        onClick={(e) =>
          handleClick(
            initialMailTemplate,
            emailConstants.TEMPLATE_TYPE_TRIGGERED,
            emailConstants.TRIGGER_EVENT_INITIAL
          )
        }
        className={styles.flow_input}
        required={scheduledMails.length > 0}
        tooltip={
          initialMailTemplate && initialMailTemplate.name.length > 31
            ? initialMailTemplate.name
            : ""
        }
      />

      <div
        className={` ${styles.trigger_scheduled} ${
          isLite ? styles.disabled : styles.activated
        }`}
      >
        <div className={styles.divider}>
          <span className={styles.divider_line}></span>
          <span className={styles.divider_word}>Scheduled emails</span>
          <span className={styles.divider_line}></span>
        </div>

        <ClickableTextField
          label="1st reminder"
          variant="outlined"
          size="small"
          fullWidth={true}
          value={scheduledMails[0] ? scheduledMails[0].name : ""}
          tooltip={
            scheduledMails[0] && scheduledMails[0].name.length > 19
              ? scheduledMails[0].name
              : ""
          }
          onClick={(e) =>
            handleClick(
              scheduledMails[0],
              emailConstants.TEMPLATE_TYPE_SCHEDULED
            )
          }
          endAdornment={
            scheduledMails[0] && scheduledMails[0].schedule_num_days
              ? `after ${scheduledMails[0].schedule_num_days} days`
              : ""
          }
          className={styles.flow_input}
        />

        <ClickableTextField
          label="2nd reminder"
          variant="outlined"
          size="small"
          fullWidth={true}
          value={scheduledMails[1] ? scheduledMails[1].name : ""}
          tooltip={
            scheduledMails[1] && scheduledMails[1].name.length > 19
              ? scheduledMails[1].name
              : ""
          }
          onClick={(e) =>
            handleClick(
              scheduledMails[1],
              emailConstants.TEMPLATE_TYPE_SCHEDULED
            )
          }
          endAdornment={
            scheduledMails[1] && scheduledMails[1].schedule_num_days
              ? `after ${scheduledMails[1].schedule_num_days} days`
              : ""
          }
          className={styles.flow_input}
        />
        <ClickableTextField
          label="3rd reminder"
          variant="outlined"
          size="small"
          fullWidth={true}
          value={scheduledMails[2] ? scheduledMails[2].name : ""}
          tooltip={
            scheduledMails[2] && scheduledMails[2].name.length > 19
              ? scheduledMails[2].name
              : ""
          }
          onClick={(e) =>
            handleClick(
              scheduledMails[2],
              emailConstants.TEMPLATE_TYPE_SCHEDULED
            )
          }
          endAdornment={
            scheduledMails[2] && scheduledMails[2].schedule_num_days
              ? `after ${scheduledMails[2].schedule_num_days} days`
              : ""
          }
          className={styles.flow_input}
        />
        {scheduledMails &&
          scheduledMails.length > 3 &&
          scheduledMails.slice(3).map((template, index) => {
            return (
              <ClickableTextField
                label={`${index + 4}th reminder`}
                variant="outlined"
                size="small"
                fullWidth={true}
                value={template ? template.name : ""}
                tooltip={
                  template && template.name.length > 19 ? template.name : ""
                }
                onClick={(e) =>
                  handleClick(template, emailConstants.TEMPLATE_TYPE_SCHEDULED)
                }
                endAdornment={
                  template && template.schedule_num_days
                    ? `after ${template.schedule_num_days} days`
                    : ""
                }
                className={styles.flow_input}
                key={template.id}
              />
            );
          })}
        <Button
          variant="outlined"
          onClick={(e) =>
            handleClick(null, emailConstants.TEMPLATE_TYPE_SCHEDULED)
          }
        >
          ADD SCHEDULED EMAIL
        </Button>
        <div className={styles.divider}>
          <span className={styles.divider_line}></span>
          <span className={styles.divider_word}>Event triggered emails</span>
          <span className={styles.divider_line}></span>
        </div>

        <ClickableTextField
          label="Discount ends"
          variant="outlined"
          size="small"
          fullWidth={true}
          className={styles.flow_input}
          value={discountEndTemplate ? discountEndTemplate.name : ""}
          tooltip={
            discountEndTemplate && discountEndTemplate.name.length > 19
              ? discountEndTemplate.name
              : ""
          }
          onClick={(e) =>
            handleClick(
              discountEndTemplate,
              emailConstants.TEMPLATE_TYPE_TRIGGERED,
              emailConstants.TRIGGER_EVENT_DISOUNT_END
            )
          }
          endAdornment={
            discountEndTemplate && discountEndTemplate.schedule_num_days
              ? `1 day before`
              : ""
          }
        />

        <ClickableTextField
          label="License validated"
          variant="outlined"
          size="small"
          fullWidth={true}
          className={styles.flow_input}
          value={licenseValidatedTemplate ? licenseValidatedTemplate.name : ""}
          tooltip={
            licenseValidatedTemplate &&
            licenseValidatedTemplate.name.length > 31
              ? licenseValidatedTemplate.name
              : ""
          }
          onClick={(e) =>
            handleClick(
              licenseValidatedTemplate,
              emailConstants.TEMPLATE_TYPE_TRIGGERED,
              emailConstants.TRIGGER_EVENT_LICENSE_VALIDATED
            )
          }
        />
        <ClickableTextField
          label="License rejected"
          variant="outlined"
          size="small"
          fullWidth={true}
          className={styles.flow_input}
          value={licenseRejectedTemplate ? licenseRejectedTemplate.name : ""}
          tooltip={
            licenseRejectedTemplate && licenseRejectedTemplate.name.length > 31
              ? licenseRejectedTemplate.name
              : ""
          }
          onClick={(e) =>
            handleClick(
              licenseRejectedTemplate,
              emailConstants.TEMPLATE_TYPE_TRIGGERED,
              emailConstants.TRIGGER_EVENT_LICENSE_REJECTED
            )
          }
        />
        <ClickableTextField
          label="Payment received"
          variant="outlined"
          size="small"
          fullWidth={true}
          className={styles.flow_input}
          value={paymentRecievedTemplate ? paymentRecievedTemplate.name : ""}
          tooltip={
            paymentRecievedTemplate && paymentRecievedTemplate.name.length > 31
              ? paymentRecievedTemplate.name
              : ""
          }
          onClick={(e) =>
            handleClick(
              paymentRecievedTemplate,
              emailConstants.TEMPLATE_TYPE_TRIGGERED,
              emailConstants.TRIGGER_EVENT_PAID
            )
          }
        />
      </div>

      <div className={styles.divider}>
        <span className={styles.divider_line}></span>
        <span className={styles.divider_word}>Custom emails</span>
        <span className={styles.divider_line}></span>
      </div>
      {customMails &&
        customMails.map((template) => {
          return (
            <ClickableTextField
              label="Custom Email"
              variant="outlined"
              size="small"
              fullWidth={true}
              className={styles.flow_input}
              value={template.name}
              tooltip={template.name.length > 31 ? template.name : ""}
              onClick={(e) =>
                handleClick(template, emailConstants.TEMPLATE_TYPE_CUSTOM)
              }
              key={template.id}
            />
          );
        })}
      <Button
        variant="outlined"
        onClick={(e) => handleClick(null, emailConstants.TEMPLATE_TYPE_CUSTOM)}
      >
        ADD CUSTOM EMAIL
      </Button>

      <UpgradePlanpopup
        title={title}
        subtitle={subtitle}
        upgradeButton={upgradeButton}
        copy_1={copy_1}
        imageUrl={UpgradeImage}
        open={displayUpgrade}
        close={() => setDisplayUpgrade(false)}
      />
    </div>
  );
};

export default EmailFlowTemplate;
