import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import caseStates from "@_configs/caseStates.config";
import emailFlowStates from "@_configs/emailFlowStates.config";
import accountStates from "@_configs/accountStates.config";
import settlementPortalStates from "@_configs/settlementPortalStates.config";

import { sidebarActions } from "@_actions/sidebar.actions";
import { templateActions } from "@_actions/template.actions";
import { getUserDataFromJwt } from "@_utils/helpers";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Collapse from "@material-ui/core/Collapse";
import SettingsIcon from "@material-ui/icons/Settings";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import BusinessIcon from "@material-ui/icons/Business";
import ImportantDevicesIcon from "@material-ui/icons/ImportantDevices";

import styles from "./Sidebar.module.css";
import {
  AssessmentOutlined,
  EmailOutlined,
  FileCopyOutlined,
} from "@material-ui/icons";
import SvgIcon from "@material-ui/core/SvgIcon";
import { userConstants } from "@_constants/user.constants";

function Sidebar() {
  const jwtData = getUserDataFromJwt();

  const dispatch = useDispatch();
  const sidebar = useSelector((state) => state.sidebarReducer);
  const emails = useSelector((state) => state.emailsReducer.emails);
  const casesReducer = useSelector((state) => state.casesReducer);

  const [open, setOpen] = useState(false);
  const [templatesOpen, setTemplatesOpen] = useState(false);
  const [openAccount, setOpenAccount] = useState(false);
  const [openSettlementPortal, setOpenSettlementPortal] = useState(false);
  const [isReportSectionOpen, setIsReportSectionOpen] = useState(false);

  function listItemClicked(state) {
    dispatch(sidebarActions.updateStatus(state));
    if (state !== "EMAIL_FLOWS") {
      dispatch(templateActions.selectTemplate(null));
    }
  }

  function handleClick() {
    setOpen(!open);
  }

  function handleTemplateNavClick() {
    setTemplatesOpen(!templatesOpen);
  }

  function handleInboundEmailNavClick(state) {
    listItemClicked(state);
  }

  function handleClickReports() {
    setIsReportSectionOpen(!isReportSectionOpen);
  }

  function handleClickAccount() {
    setOpenAccount(!openAccount);
  }

  function handleClickSettlementPortal() {
    setOpenSettlementPortal(!openSettlementPortal);
  }

  return (
    <div className={styles.sidebar}>
      <div className={styles.navbar}>
        {/* Case section */}
        <List component="nav">
          {Object.keys(caseStates).map((state, index) => {
            const selected = sidebar.status === state;
            const stateObj = caseStates[state];
            const isCases = stateObj.key === "CASES";

            return (
              <ListItem
                key={index}
                button
                selected={selected}
                onClick={() => listItemClicked(stateObj.key)}
                className={isCases ? styles.cases_item : styles.status_item}
                classes={{ root: styles.List_item, selected: styles.selected }}
              >
                <div
                  className={isCases ? styles.cases_icon : styles.status_icon}
                >
                  {stateObj.icon}
                </div>

                <div className={styles.row_container}>
                  <div
                    className={
                      isCases ? styles.cases_title : styles.status_title
                    }
                  >
                    {stateObj.name}
                  </div>
                  <div className={styles.count}>
                    {
                      casesReducer.cases_count[
                        stateObj.key === "CASES" ? "all" : stateObj.key
                      ]
                    }
                  </div>
                </div>
              </ListItem>
            );
          })}

          {/* Inbound Email section */}
          {emails.length > 0 && (
            <ListItem
              button
              classes={{ root: styles.List_item, selected: styles.selected }}
              className={styles.cases_item}
              onClick={() => handleInboundEmailNavClick("INBOUNDEMAILS")}
            >
              <EmailOutlined className={styles.settings_icon} />
              <div className={styles.row_container}>
                <div className={styles.cases_title}>Unmapped Emails</div>
                {emails.filter((email) => email.unread === 1).length > 0 && (
                  <div className={styles.count}>
                    {emails.filter((email) => email.unread === 1).length}
                  </div>
                )}
              </div>
            </ListItem>
          )}

          {/* Email flows section */}
          <ListItem
            button
            classes={{ root: styles.List_item, selected: styles.selected }}
            className={styles.cases_item}
            onClick={handleTemplateNavClick}
          >
            <SvgIcon
              className={styles.settings_icon}
              children={
                <>
                  <path d="M5 5h2v3h10V5h2v5h2V5c0-1.1-.9-2-2-2h-4.18C14.4 1.84 13.3 1 12 1s-2.4.84-2.82 2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h6v-2H5V5zm7-2c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1z"></path>
                  <path d="M21 11.5 15.51 17l-3.01-3-1.5 1.5 4.51 4.5 6.99-7z"></path>
                </>
              }
            />
            <div className={styles.row_container}>
              <div className={styles.cases_title}>Templates</div>
            </div>
            {templatesOpen ? <ExpandLess /> : <ExpandMore />}
          </ListItem>

          <Collapse in={templatesOpen} timeout="auto" unmountOnExit>
            <div>
              <List component="div" disablePadding>
                {Object.keys(emailFlowStates).map((state, index) => {
                  const selected = sidebar.status === state;
                  const stateObj = emailFlowStates[state];

                  return (
                    <div
                      className={styles.email_flows_agent}
                      key={stateObj.key}
                    >
                      <ListItem
                        button
                        selected={selected}
                        onClick={() => listItemClicked(stateObj.key)}
                        className={styles.status_item}
                        classes={{
                          root: styles.List_item,
                          selected: styles.selected,
                        }}
                      >
                        <div className={styles.status_icon}>
                          <EmailOutlined />
                        </div>

                        <div className={styles.row_container}>
                          <div className={styles.status_title}>
                            {stateObj.name}
                          </div>
                        </div>
                      </ListItem>
                    </div>
                  );
                })}
              </List>

              <List component="div" disablePadding>
                <div
                  className={styles.email_flows_agent}
                  key={"Case Auto Fill"}
                >
                  <ListItem
                    button
                    selected={sidebar.status === "CASETEMPLATES"}
                    onClick={() => listItemClicked("CASETEMPLATES")}
                    className={styles.status_item}
                    classes={{
                      root: styles.List_item,
                      selected: styles.selected,
                    }}
                  >
                    <div className={styles.status_icon}>
                      <SvgIcon
                        children={
                          <path d="M14 2H4c-1.1 0-2 .9-2 2v10h2V4h10V2zm4 4H8c-1.1 0-2 .9-2 2v10h2V8h10V6zm2 4h-8c-1.1 0-2 .9-2 2v8c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2v-8c0-1.1-.9-2-2-2zm0 10h-8v-8h8v8z"></path>
                        }
                      />
                    </div>

                    <div className={styles.row_container}>
                      <div className={styles.status_title}>Case Auto Fill</div>
                    </div>
                  </ListItem>
                </div>
              </List>
              {jwtData.subscription !==
                userConstants.USER_SUBSCRIPTION_LITE && (
                <List component="div" disablePadding>
                  <div
                    className={styles.email_flows_agent}
                    key={"Invoice Templates"}
                  >
                    <ListItem
                      button
                      selected={sidebar.status === "INVOICETEMPLATES"}
                      onClick={() => listItemClicked("INVOICETEMPLATES")}
                      className={styles.status_item}
                      classes={{
                        root: styles.List_item,
                        selected: styles.selected,
                      }}
                    >
                      <div className={styles.status_icon}>
                        <SvgIcon
                          children={
                            <path d="M14 2H4c-1.1 0-2 .9-2 2v10h2V4h10V2zm4 4H8c-1.1 0-2 .9-2 2v10h2V8h10V6zm2 4h-8c-1.1 0-2 .9-2 2v8c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2v-8c0-1.1-.9-2-2-2zm0 10h-8v-8h8v8z"></path>
                          }
                        />
                      </div>

                      <div className={styles.row_container}>
                        <div className={styles.status_title}>Invoices</div>
                      </div>
                    </ListItem>
                  </div>
                </List>
              )}
            </div>
          </Collapse>

          {/* Reports */}
          <div>
            <ListItem
              key={"reports_key"}
              button
              classes={{ root: styles.List_item, selected: styles.selected }}
              className={styles.cases_title}
              onClick={handleClickReports}
            >
              <AssessmentOutlined className={styles.settings_icon} />
              <div className={styles.row_container}>
                <div className={styles.reports_title}>Accounting</div>
              </div>
              {isReportSectionOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItem>

            <Collapse in={isReportSectionOpen} timeout="auto" unmountOnExit>
              <div className={styles.settings_nav}>
                <List component="div" disablePadding>
                  <div className={styles.reports_agent} key={"paymentReports"}>
                    <ListItem
                      key={"payments_key"}
                      button
                      selected={sidebar.status === "PAYMENT_REPORTS"}
                      onClick={() => listItemClicked("PAYMENT_REPORTS")}
                      className={styles.status_item}
                      classes={{
                        root: styles.List_item,
                        selected: styles.selected,
                      }}
                    >
                      <div className={styles.status_icon}>
                        <SvgIcon
                          children={
                            <path d="M19 14V6c0-1.1-.9-2-2-2H3c-1.1 0-2 .9-2 2v8c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2zm-2 0H3V6h14v8zm-7-7c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3zm13 0v11c0 1.1-.9 2-2 2H4v-2h17V7h2z"></path>
                          }
                        />
                      </div>

                      <div className={styles.row_container}>
                        <div className={styles.status_title}>Payments</div>
                      </div>
                    </ListItem>
                  </div>
                </List>
                <List component="div" disablePadding>
                  <div className={styles.reports_agent} key={"paymentReports"}>
                    <ListItem
                      key={"payments_key"}
                      button
                      selected={sidebar.status === "INVOICE_MANAGEMENT"}
                      onClick={() => listItemClicked("INVOICE_MANAGEMENT")}
                      className={styles.status_item}
                      classes={{
                        root: styles.List_item,
                        selected: styles.selected,
                      }}
                    >
                      <div className={styles.status_icon}>
                        <FileCopyOutlined />
                      </div>

                      <div className={styles.row_container}>
                        <div className={styles.status_title}>Invoices</div>
                      </div>
                    </ListItem>
                  </div>
                </List>
              </div>
            </Collapse>
          </div>

          {/* Display the setting section just for admin role */}
          {jwtData.user_role === "ADMIN" && (
            <div className={styles.settings_nav}>
              <ListItem
                button
                classes={{ root: styles.List_item, selected: styles.selected }}
                className={styles.cases_item}
                onClick={handleClick}
              >
                <SettingsIcon className={styles.settings_icon} />
                <div className={styles.row_container}>
                  <div className={styles.cases_title}>Admin</div>
                </div>
                {open ? <ExpandLess /> : <ExpandMore />}
              </ListItem>

              <Collapse in={open} timeout="auto" unmountOnExit>
                <div className={styles.settings_container}>
                  <List component="div" disablePadding>
                    <ListItem
                      button
                      classes={{
                        root: styles.List_item,
                        selected: styles.selected,
                      }}
                      className={styles.cases_item}
                      onClick={handleClickAccount}
                    >
                      <div className={styles.status_icon}>
                        <BusinessIcon />
                      </div>
                      <div className={styles.row_container}>
                        <div className={styles.status_title}>Account</div>
                      </div>
                      {openAccount ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>

                    <Collapse in={openAccount} timeout="auto" unmountOnExit>
                      {Object.keys(accountStates).map((state, index) => {
                        const selected = sidebar.status === state;
                        const stateObj = accountStates[state];
                        return (
                          <ListItem
                            key={index}
                            button
                            selected={selected}
                            onClick={() => listItemClicked(stateObj.key)}
                            className={styles.status_item}
                            classes={{
                              root: styles.List_item,
                              selected: styles.selected,
                            }}
                          >
                            <div
                              className={
                                stateObj.icon
                                  ? styles.status_icon
                                  : styles.status_no_icon
                              }
                            >
                              {stateObj.icon}
                            </div>

                            <div className={styles.row_container}>
                              <div className={styles.status_title}>
                                {stateObj.name}
                              </div>
                            </div>
                          </ListItem>
                        );
                      })}
                    </Collapse>

                    <ListItem
                      button
                      classes={{
                        root: styles.List_item,
                        selected: styles.selected,
                      }}
                      className={styles.cases_item}
                      onClick={handleClickSettlementPortal}
                    >
                      <div className={styles.status_icon}>
                        {" "}
                        <ImportantDevicesIcon />
                      </div>
                      <div className={styles.row_container}>
                        <div className={styles.status_title}>
                          Settlement Portal
                        </div>
                      </div>
                      {openSettlementPortal ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>

                    <Collapse
                      in={openSettlementPortal}
                      timeout="auto"
                      unmountOnExit
                    >
                      {Object.keys(settlementPortalStates).map(
                        (state, index) => {
                          const selected = sidebar.status === state;
                          const stateObj = settlementPortalStates[state];
                          return (
                            <ListItem
                              key={index}
                              button
                              selected={selected}
                              onClick={() => listItemClicked(stateObj.key)}
                              className={styles.status_item}
                              classes={{
                                root: styles.List_item,
                                selected: styles.selected,
                              }}
                            >
                              <div
                                className={
                                  stateObj.icon
                                    ? styles.status_icon
                                    : styles.status_no_icon
                                }
                              >
                                {stateObj.icon}
                              </div>

                              <div className={styles.row_container}>
                                <div className={styles.status_title}>
                                  {stateObj.name}
                                </div>
                              </div>
                            </ListItem>
                          );
                        }
                      )}{" "}
                    </Collapse>
                  </List>
                </div>
              </Collapse>
            </div>
          )}
        </List>
      </div>
    </div>
  );
}

export default Sidebar;
