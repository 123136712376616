import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Claer from "@material-ui/icons/Clear";
import { useDispatch, useSelector } from "react-redux";
import styles from "./Cases.module.css";
import { casesActions } from "@_actions/cases.actions";
import config from "@_configs/api.config";
import CasesRow from "@_components/CaseRow/CaseRow";
import CaseTemplates from "@_components/CaseTemplates/CaseTemplates";
import CaseDetails from "@_components/CaseDetails/CaseDetails";
import { caseActions } from "@_actions/case.actions";
import Account from "@_pages/Account/Account";
import AddCasePopup from "@_components/popups/AddCasePopup/AddCasePopup";
import TextfieldInput from "@_components/TextfieldInput/TextfieldInput";
import Emails from "@_pages/EmailFlows/EmailFlows";
import BaseSettings from "@_pages/BaseSettings/BaseSettings";
import Languages from "@_pages/Languages/Languages";
import EmailSetup from "@_pages/EmailSetup/EmailSetup";
import UserManagement from "@_pages/UserManagement/UserManagement";
import Subscription from "@_pages/Subscription/Subscription";
import PaymentSetup from "@_pages/PaymentSetup/PaymentSetup";
import PaymentReports from "@_pages/PaymentReports/PaymentReports";
import InboundEmails from "@_pages/InboundEmails/InboundEmails";
import caseStates from "@_configs/caseStates.config";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  AirlineSeatFlat,
  EventBusy,
  EventBusyOutlined,
  FilterList,
  InsertDriveFileOutlined,
  Mail,
  PanTool,
  TimerOffTwoTone,
  Warning,
} from "@material-ui/icons";
import { caseTemplateActions } from "@_actions/caseTemplate.actions";
import { ClickAwayListener, Paper } from "@material-ui/core";
import { emailsActions } from "@_actions/emails.actions";
import { caseConstants } from "@_constants/case.constants";
import InvoiceTemplates from "@_components/InvoiceTemplates/InvoiceTemplates";
import InvoiceManagement from "@_components/InvoiceManagement/InvoiceManagement";

function Cases() {
  const casesReducer = useSelector((state) => state.casesReducer);
  const sidebarReducer = useSelector((state) => state.sidebarReducer);
  const caseReducer = useSelector((state) => state.caseReducer);

  const [searchValue, setSearchValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [currentSearchObject, setCurrentSearchObject] = useState({});
  const [filterOverdueReminder, setFilterOverdueReminder] = useState(false);
  const [filterUnreadMails, setFilterUnreadMails] = useState(false);
  const [filterUploadedLicenses, setFilterUploadedLicenses] = useState(false);
  const [filterFailedEmails, setFilterFailedEmails] = useState(false);
  const [filterPaused, setFilterPaused] = useState(false);
  const [filterRequireAttention, setFilterRequireAttention] = useState(false);
  const [filterIdle, setFilterIdle] = useState(false);
  const [filterFailedPayment, setFilterFailedPayment] = useState(false);
  const [activeFilter, setActiveFilter] = useState(
    caseConstants.CASE_FILTER_NONE
  );
  const [filterMenuOpen, setFilterMenuOpen] = useState(false);
  const [lastSidebarStatus, setLastSidebarStatus] = useState("CASES");

  const [openAddCasePopup, setOpenAddCasePopup] = useState(false);
  const selectedCaseData =
    caseReducer.case[sidebarReducer.selections[sidebarReducer.status]];

  const dispatch = useDispatch();

  useEffect(() => {
    //   dispatch(casesActions.getCases(createSearchObject()));
    dispatch(emailsActions.loadEmails());
    dispatch(caseTemplateActions.getCaseTemplates());
    dispatch(casesActions.getCasesCount());
  }, [dispatch]);

  const createSearchObject = (
    searchValue,
    filterOverdueReminder,
    filterUnreadMails,
    filterUploadedLicenses,
    filterFailedEmails,
    filterPaused,
    filterRequireAttention,
    filterIdle,
    filterFailedPayment
  ) => {
    var searchObject = {};
    searchObject.sort = { key: "date_state_updated", ascending: false };
    let filter = {
      unread_emails: filterUnreadMails,
      unchecked_licenses: filterUploadedLicenses,
      overdue_reminders: filterOverdueReminder,
      failed_emails: filterFailedEmails,
      paused_scheduled_emails: filterPaused,
      action_required: filterRequireAttention,
      idle: filterIdle,
      failed_payments: filterFailedPayment,
    };
    if (searchValue && searchValue.trim().length > 0) {
      filter.search = searchValue;
    }
    searchObject.filter = filter;
    searchObject.paging = { page: 1, page_size: config.PAGE_SIZE };
    if (sidebarReducer.status !== "CASES") {
      searchObject.filter.state = sidebarReducer.status;
    }
    return searchObject;
  };

  useEffect(() => {
    // check if current page is a case page
    if (Object.keys(caseStates).indexOf(sidebarReducer.status) === -1) return;

    var s;

    s = createSearchObject(
      searchValue,
      filterOverdueReminder,
      filterUnreadMails,
      filterUploadedLicenses,
      filterFailedEmails,
      filterPaused,
      filterRequireAttention,
      filterIdle,
      filterFailedPayment
    );

    setCurrentSearchObject(s);
    dispatch(casesActions.getCases(s, true));

    if (sidebarReducer.status !== lastSidebarStatus) {
      handleClearClick();
      setFilterOverdueReminder(false);
      setFilterUnreadMails(false);
      setFilterUploadedLicenses(false);
      setFilterFailedEmails(false);
      setFilterPaused(false);
      setFilterRequireAttention(false);
      setFilterFailedPayment(false);
      setActiveFilter(caseConstants.CASE_FILTER_NONE);
      setLastSidebarStatus(sidebarReducer.status);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    sidebarReducer.status,

    searchValue,
    filterOverdueReminder,
    filterUnreadMails,
    filterUploadedLicenses,
    filterFailedEmails,
    filterPaused,
    filterRequireAttention,
    lastSidebarStatus,
    filterIdle,
    filterFailedPayment,
  ]);

  useEffect(() => {
    if (casesReducer.status.CASES_GET === "success") {
      dispatch(casesActions.clearStateMessage("CASES_GET"));
      setIsLoading(false);
      console.log("loaded now: " + casesReducer.cases.length);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [casesReducer.status]);

  const loadFunc = () => {
    if (!isLoading && casesReducer.status.CASES_GET !== "request") {
      setIsLoading(true);
      console.log("would load");
      let s = { ...currentSearchObject };
      if (!s.paging) s = createSearchObject();

      s.paging.page =
        Math.floor(casesReducer.cases.length / config.PAGE_SIZE) +
        1 +
        (casesReducer.cases.length % config.PAGE_SIZE > 0 ? 1 : 0);

      dispatch(casesActions.getCases(s));
      setCurrentSearchObject(s);
    }
  };

  const hasMore = () => {
    return true;
    //return casesReducer.cases_count.all !== casesReducer.cases.length;
  };

  const handleSearchInputChange = (event) => {
    setSearchValue(event.target.value);
  };

  const handleClearClick = () => {
    setSearchValue("");
  };

  if (sidebarReducer.status === "BUSINESS_DETAILS") {
    return (
      <div className={styles.root}>
        <Account />
      </div>
    );
  }

  if (sidebarReducer.status === "USERS") {
    return (
      <div className={styles.root}>
        <UserManagement />
      </div>
    );
  }

  if (sidebarReducer.status === "BASE_SETTINGS") {
    return (
      <div className={styles.root}>
        <BaseSettings />
      </div>
    );
  }

  if (sidebarReducer.status === "TRANSLATIONS") {
    return (
      <div className={styles.root}>
        <Languages />
      </div>
    );
  }

  if (sidebarReducer.status === "EMAIL_FLOWS") {
    return (
      <div className={styles.root}>
        <Emails />
      </div>
    );
  }

  if (sidebarReducer.status === "CASETEMPLATES") {
    return (
      <div className={styles.root}>
        <CaseTemplates />
      </div>
    );
  }
  if (sidebarReducer.status === "INVOICETEMPLATES") {
    return (
      <div className={styles.root}>
        <InvoiceTemplates />
      </div>
    );
  }

  if (sidebarReducer.status === "EMAIL_SETUP") {
    return (
      <div className={styles.root}>
        <EmailSetup />
      </div>
    );
  }

  if (sidebarReducer.status === "INBOUNDEMAILS") {
    return (
      <div>
        <InboundEmails />
      </div>
    );
  }

  if (sidebarReducer.status === "SUBSCRIPTION") {
    return (
      <div className={styles.root}>
        <Subscription />
      </div>
    );
  }

  if (sidebarReducer.status === "PAYMENT_SETUP") {
    return (
      <div className={styles.root}>
        <PaymentSetup />
      </div>
    );
  }

  if (sidebarReducer.status === "PAYMENT_REPORTS") {
    return (
      <div className={styles.root}>
        <PaymentReports />
      </div>
    );
  }

  if (sidebarReducer.status === "INVOICE_MANAGEMENT") {
    return (
      <div className={styles.root}>
        <InvoiceManagement />
      </div>
    );
  }

  const handleOpenAddCasePopup = () => {
    dispatch(caseActions.clearStateMessage("CASE_ADD_FINDING"));
    setOpenAddCasePopup(true);
  };

  const handleFilterIconClicked = () => {
    if (activeFilter === caseConstants.CASE_FILTER_NONE) {
      setFilterMenuOpen(true);
    } else {
      activateFilter(caseConstants.CASE_FILTER_NONE);
    }
  };

  const renderActiveFilter = () => {
    return (
      <Button
        onClick={handleFilterIconClicked}
        className={styles.filterIconActive}
      >
        {renderActiveFilterElement()}
      </Button>
    );
  };

  const renderActiveFilterElement = () => {
    switch (activeFilter) {
      case caseConstants.CASE_FILTER_NONE:
        return (
          <>
            <FilterList></FilterList>
            <b style={{ marginLeft: "3px" }}>Filter</b>
          </>
        );
      case caseConstants.CASE_FILTER_MAIL:
        return (
          <>
            <Mail></Mail>
            <b style={{ marginLeft: "3px" }}>Unread Mails</b>
          </>
        );
      case caseConstants.CASE_FILTER_REMINDER:
        return (
          <>
            <EventBusy />
            <b style={{ marginLeft: "3px" }}>Reminder Overdue</b>
          </>
        );
      case caseConstants.CASE_FILTER_UPLOADED_LICENSE:
        return (
          <>
            <InsertDriveFileOutlined />
            <b style={{ marginLeft: "3px" }}>Uploaded Licenses</b>
          </>
        );

      case caseConstants.CASE_FILTER_FAILED_EMAIL:
        return (
          <>
            <Warning />
            <b style={{ marginLeft: "3px" }}>Failed Emails</b>
          </>
        );
      case caseConstants.CASE_FILTER_FAILED_PAYMENT:
        return (
          <>
            <Warning />
            <b style={{ marginLeft: "3px" }}>Failed Payments</b>
          </>
        );

      case caseConstants.CASE_FILTER_PAUSED:
        return (
          <>
            <TimerOffTwoTone />
            <b style={{ marginLeft: "3px" }}>Paused Scheduled Emails</b>
          </>
        );

      case caseConstants.CASE_FILTER_REQUIRE_ATTENTION:
        return (
          <>
            <PanTool />
            <b style={{ marginLeft: "3px" }}>Require Attention</b>
          </>
        );
      case caseConstants.CASE_FILTER_IDLE_CASES:
        return (
          <>
            <AirlineSeatFlat />
            <b style={{ marginLeft: "3px" }}>Idle Cases</b>
          </>
        );

      default:
        return <></>;
    }
  };

  const activateFilter = (filter) => {
    setActiveFilter(filter);
    setFilterMenuOpen(false);
    setFilterOverdueReminder(false);
    setFilterUnreadMails(false);
    setFilterUploadedLicenses(false);
    setFilterFailedEmails(false);
    setFilterIdle(false);
    setFilterPaused(false);
    setFilterRequireAttention(false);
    setFilterFailedPayment(false);
    switch (filter) {
      case caseConstants.CASE_FILTER_MAIL:
        setFilterUnreadMails(true);
        break;
      case caseConstants.CASE_FILTER_REMINDER:
        setFilterOverdueReminder(true);
        break;
      case caseConstants.CASE_FILTER_UPLOADED_LICENSE:
        setFilterUploadedLicenses(true);
        break;
      case caseConstants.CASE_FILTER_FAILED_EMAIL:
        setFilterFailedEmails(true);
        break;
      case caseConstants.CASE_FILTER_FAILED_PAYMENT:
        setFilterFailedPayment(true);
        break;
      case caseConstants.CASE_FILTER_PAUSED:
        setFilterPaused(true);
        break;
      case caseConstants.CASE_FILTER_REQUIRE_ATTENTION:
        setFilterRequireAttention(true);
        break;
      case caseConstants.CASE_FILTER_IDLE_CASES:
        setFilterIdle(true);
        break;
      default:
        break;
    }
  };

  return (
    <div className={styles.root}>
      <div className={styles.case_list_container}>
        <div className={styles.search_case_container}>
          <TextfieldInput
            label="search"
            id={"case_search"}
            name={"case_search"}
            value={searchValue}
            onChange={handleSearchInputChange}
            className={styles.input}
            InputProps={
              searchValue
                ? {
                    endAdornment: (
                      <IconButton
                        sx={{
                          visibility: searchValue ? "visible" : "hidden",
                        }}
                        onClick={handleClearClick}
                        style={{ width: 25, height: 25 }}
                      >
                        <Claer />
                      </IconButton>
                    ),
                  }
                : {}
            }
          />
        </div>
        <div className={styles.add_case_container}>
          <div className={styles.add_case_button}>
            {" "}
            <Button onClick={handleOpenAddCasePopup} variant="outlined">
              Add New Case
            </Button>
          </div>
          <div className={styles.filter_container}>
            <div>{renderActiveFilter()}</div>
            {activeFilter !== caseConstants.CASE_FILTER_NONE || searchValue ? (
              <div className={styles.filter_counter}>{casesReducer.count}</div>
            ) : (
              ""
            )}
          </div>

          {filterMenuOpen && (
            <>
              <ClickAwayListener onClickAway={() => setFilterMenuOpen(false)}>
                <Paper className={styles.filterMenuPaper}>
                  <div
                    className={styles.filterMenuRow}
                    onClick={() =>
                      activateFilter(
                        caseConstants.CASE_FILTER_REQUIRE_ATTENTION
                      )
                    }
                  >
                    <PanTool
                      style={{ marginBottom: "-5px", marginRight: "8px" }}
                    />
                    Action Required
                  </div>
                  <div
                    className={styles.filterMenuRow}
                    onClick={() =>
                      activateFilter(caseConstants.CASE_FILTER_MAIL)
                    }
                  >
                    <Mail
                      style={{ marginBottom: "-5px", marginRight: "8px" }}
                    ></Mail>
                    Unread Mails{" "}
                  </div>
                  <div
                    className={styles.filterMenuRow}
                    onClick={() =>
                      activateFilter(caseConstants.CASE_FILTER_REMINDER)
                    }
                  >
                    <EventBusyOutlined
                      style={{ marginBottom: "-5px", marginRight: "8px" }}
                    />
                    Overdue Reminder
                  </div>
                  <div
                    className={styles.filterMenuRow}
                    onClick={() =>
                      activateFilter(caseConstants.CASE_FILTER_UPLOADED_LICENSE)
                    }
                  >
                    <InsertDriveFileOutlined
                      style={{ marginBottom: "-5px", marginRight: "8px" }}
                    />
                    Uploaded Licenses
                  </div>

                  <div
                    className={styles.filterMenuRow}
                    onClick={() =>
                      activateFilter(caseConstants.CASE_FILTER_FAILED_EMAIL)
                    }
                  >
                    <Warning
                      style={{ marginBottom: "-5px", marginRight: "8px" }}
                    />
                    Failed Emails
                  </div>
                  <div
                    className={styles.filterMenuRow}
                    onClick={() =>
                      activateFilter(caseConstants.CASE_FILTER_FAILED_PAYMENT)
                    }
                  >
                    <Warning
                      style={{ marginBottom: "-5px", marginRight: "8px" }}
                    />
                    Failed Payments
                  </div>

                  <div
                    className={styles.filterMenuRow}
                    onClick={() =>
                      activateFilter(caseConstants.CASE_FILTER_PAUSED)
                    }
                  >
                    <TimerOffTwoTone
                      style={{ marginBottom: "-5px", marginRight: "8px" }}
                    />
                    Paused Scheduled Emails
                  </div>
                  <div
                    className={styles.filterMenuRow}
                    onClick={() =>
                      activateFilter(caseConstants.CASE_FILTER_IDLE_CASES)
                    }
                  >
                    <AirlineSeatFlat
                      style={{ marginBottom: "-5px", marginRight: "8px" }}
                    />
                    Idle Cases
                  </div>
                </Paper>
              </ClickAwayListener>
            </>
          )}
        </div>
        <div
          id="scrollableDiv"
          style={{
            height: "calc(100vh - 280px)",
            overflow: "auto",
            marginRight: "-10px",
            paddingRight: "10px",
          }}
        >
          <InfiniteScroll
            dataLength={casesReducer.cases.length}
            next={loadFunc}
            hasMore={hasMore}
            loader={
              <>
                {casesReducer.status.CASES_GET === "request" && (
                  <div className="loader" key={0}>
                    Loading ...
                  </div>
                )}
              </>
            }
            scrollableTarget="scrollableDiv"
            endMessage={<p>No more data to load.</p>}
          >
            {casesReducer.cases.map((theCase, index) => {
              const selected =
                sidebarReducer.selections[sidebarReducer.status] === theCase.id;
              return (
                <div
                  key={"div_" + index}
                  className={
                    theCase.hidden
                      ? styles.case_row_folded
                      : styles.case_row_unfolded
                  }
                >
                  <CasesRow
                    key={index}
                    data={theCase}
                    index={index}
                    selected={selected}
                  />
                </div>
              );
            })}
          </InfiniteScroll>
        </div>
      </div>
      <div className={styles.case_details_container}>
        <CaseDetails data={selectedCaseData} />
      </div>
      <AddCasePopup
        open={openAddCasePopup}
        close={() => setOpenAddCasePopup(false)}
      />
    </div>
  );
}

export default Cases;
