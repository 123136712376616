import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { emailActions } from "@_actions/email_flows.actions";
import { templateActions } from "@_actions/template.actions";
import { getUserDataFromJwt } from "@_utils/helpers";
import RenameFlow from "@_components/RenameFlow/RenameFlow";
import styles from "./EmailFlowRow.module.css";
import AccountTreeIcon from "@material-ui/icons/AccountTree";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import MenuItem from "@material-ui/core/MenuItem";
import ListItem from "@material-ui/core/ListItem";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuList from "@material-ui/core/MenuList";
import EmailFlowTemplate from "@_components/EmailFlowTemplate/EmailFlowTemplate";
import { emailConstants } from "@_constants/email_flows.constants";
import WarningIcon from "@material-ui/icons/Warning";
import { Tooltip } from "@material-ui/core";
import { sidebarActions } from "@_actions/sidebar.actions";

function EmailFlowRow(props) {
  const { data, handleSelectedFlow, selectedFlow } = props;
  let emailFlow = data;
  const ref = useRef(null);
  const anchorRef = React.useRef(null);
  const dispatch = useDispatch();
  const [openTemplateFlag, setOpenTemplateList] = useState(false);
  const [templateList, setTemplateList] = useState([]);
  const [openEditPopup, setOpenEditPopup] = useState(false);
  const [renameFlow, setRenameFlow] = useState(false);
  const [newFlowName, setNewFlowName] = useState({
    name: "",
  });
  const [inputChange, setInputChange] = useState(false);
  const [trigerredMails, setTrigerredMails] = useState([]);
  const [scheduledMails, setScheduledMails] = useState([]);
  const [customMails, setCustomMails] = useState([]);
  const [initialMailTemplate, setInitialMailTemplate] = useState(null);
  const [licenseValidatedTemplate, setLicenseValidatedTemplate] =
    useState(null);
  const [licenseRejectedTemplate, setLicenseRejectedTemplate] = useState(null);
  const [paymentRecievedTemplate, setPaymentRecievedTemplate] = useState(null);
  const [discountEndTemplate, setDiscountEndTemplate] = useState(null);

  useEffect(() => {
    dispatch(templateActions.getTemplates(emailFlow.id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const emailFlowReducer = useSelector((state) => state.emailFlowReducer);
  const templateReducer = useSelector((state) => state.templateReducer);
  const jwtData = getUserDataFromJwt();

  const handleOpenEditPopup = (event) => {
    event.stopPropagation();
    setOpenEditPopup(true);
  };

  const handleCloseEditPopup = () => {
    setOpenEditPopup(false);
  };

  const handleDeleteFlow = (event) => {
    event.stopPropagation();
    dispatch(emailActions.deleteEmail(emailFlow.id));
    dispatch(sidebarActions.updateSelections(null));
  };

  const handleRenameFlow = (event) => {
    event.stopPropagation();
    setRenameFlow(true);
  };

  const handleInputChange = (e) => {
    setInputChange(true);
    const { name, value } = e.target;
    setNewFlowName((newFlowName) => ({
      ...newFlowName,
      [name]: value,
    }));
  };

  const handleSubmitInput = (e) => {
    e.preventDefault();
    if (newFlowName.name) {
      dispatch(
        emailActions.updateEmail(emailFlow.id, {
          name: newFlowName.name,
        })
      );
    } else {
      setRenameFlow(false);
      setOpenEditPopup(false);
    }
  };

  useEffect(() => {
    if (emailFlowReducer.status.EMAIL_FLOW_PUT === "success") {
      setNewFlowName({ name: "" });
      setRenameFlow(false);
    }
  }, [dispatch, emailFlowReducer.status.EMAIL_FLOW_PUT]);

  useEffect(() => {
    if (templateReducer.success && templateReducer.templates === "") {
      dispatch(templateActions.getTemplates(emailFlow.id));
    }
  });

  useEffect(() => {
    if (!selectedFlow) setOpenTemplateList(false);
  }, [selectedFlow]);

  const handleToggleTemplateList = () => {
    if (!openTemplateFlag) {
      handleGetTemplates();
      handleSelectedFlow();
    }
    dispatch(templateActions.selectTemplate({}));
    setOpenTemplateList(!openTemplateFlag);
  };

  const handleAddTemplate = (emailFlowID, templateType, trigger) => {
    dispatch(emailActions.selectEmail(emailFlowID));
    dispatch(
      templateActions.addTemplateState(true, templateType, trigger, emailFlowID)
    );
  };

  const handleSelectTemplate = (data) => {
    dispatch(emailActions.selectEmail(emailFlow.id));
    data["emailFlowId"] = emailFlow.id;
    dispatch(templateActions.selectTemplate(data));
  };

  const handleGetTemplates = () => {
    dispatch(emailActions.selectEmail(emailFlow.id));
  };

  useEffect(() => {
    if (templateReducer.flow_templates) {
      let key = Object.keys(templateReducer.flow_templates);
      key.forEach((key) => {
        let values = templateReducer.flow_templates[key];
        if (key === emailFlow.id) {
          setTemplateList(values);
        } else return;
      });
    }
  }, [templateReducer.flow_templates, emailFlow.id]);

  useEffect(() => {
    let updatedTrigerredMails = [];
    let updatedScheduledMails = [];
    let updatedCustomMails = [];
    setInitialMailTemplate(null);
    setLicenseValidatedTemplate(null);
    setLicenseRejectedTemplate(null);
    setPaymentRecievedTemplate(null);
    setDiscountEndTemplate(null);
    templateList.forEach((template) => {
      switch (template.type) {
        case emailConstants.TEMPLATE_TYPE_TRIGGERED:
          updatedTrigerredMails.push(template);

          switch (template.trigger_event) {
            case emailConstants.TRIGGER_EVENT_INITIAL:
              setInitialMailTemplate(template);
              break;
            case emailConstants.TRIGGER_EVENT_LICENSE_VALIDATED:
              setLicenseValidatedTemplate(template);
              break;
            case emailConstants.TRIGGER_EVENT_LICENSE_REJECTED:
              setLicenseRejectedTemplate(template);
              break;
            case emailConstants.TRIGGER_EVENT_PAID:
              setPaymentRecievedTemplate(template);
              break;
            case emailConstants.TRIGGER_EVENT_DISOUNT_END:
              setDiscountEndTemplate(template);
              break;
            default:
              break;
          }
          break;

        case emailConstants.TEMPLATE_TYPE_SCHEDULED:
          updatedScheduledMails.push(template);
          break;

        case emailConstants.TEMPLATE_TYPE_CUSTOM:
          updatedCustomMails.push(template);
          break;

        default:
          break;
      }
    });

    setTrigerredMails(updatedTrigerredMails);
    setScheduledMails(
      updatedScheduledMails.sort(
        (s1, s2) => s1.schedule_num_days - s2.schedule_num_days
      )
    );
    updatedCustomMails.sort((c1, c2) => c1.name.localeCompare(c2.name));
    setCustomMails(updatedCustomMails);
  }, [templateList]);

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpenEditPopup(false);
    }
  }

  useEffect(() => {
    setRenameFlow(renameFlow);
  }, [renameFlow]);

  return (
    <div>
      {renameFlow ? (
        <div
          className={styles.email_row_root}
          key={emailFlow.id}
          onClick={handleGetTemplates}
          ref={ref}
        >
          {props.message}
          <ListItem className={styles.flow_list_item}>
            <RenameFlow
              styles={styles}
              name={newFlowName.name}
              handleSubmitInput={handleSubmitInput}
              handleInputChange={handleInputChange}
              prevValue={emailFlow.name}
              id={emailFlow.id}
              onClickOutside={() => {
                setRenameFlow(false);
                setOpenEditPopup(false);
              }}
              customer_id={jwtData.customer_id}
              inputChange={inputChange}
            />
          </ListItem>
        </div>
      ) : (
        <div
          className={styles.email_row_root}
          key={emailFlow.id}
          onClick={(e) => {
            handleToggleTemplateList();
          }}
        >
          <ListItem className={styles.flow_list_item}>
            <AccountTreeIcon className={styles.account_tree_icon} />
            <b className={styles.input_container}>{emailFlow.name}</b>
            <div className={styles.edit_icon_container}>
              {!initialMailTemplate && scheduledMails.length > 0 && (
                <Tooltip
                  title="initial email template missing"
                  placement="top"
                  arrow
                >
                  <WarningIcon className={styles.warning_icon} />
                </Tooltip>
              )}
              <MoreHorizIcon
                className={styles.edit_icon}
                onClick={handleOpenEditPopup}
                ref={anchorRef}
                aria-controls={openEditPopup ? "menu-list-grow" : undefined}
                aria-haspopup="true"
              />
              <Popper
                open={openEditPopup}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                className={styles.edit_popup}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleCloseEditPopup}>
                    <MenuList
                      autoFocusItem={openEditPopup}
                      id="menu-list-grow"
                      onKeyDown={handleListKeyDown}
                    >
                      <MenuItem onClick={(e) => handleRenameFlow(e)}>
                        Rename
                      </MenuItem>
                      <MenuItem onClick={(e) => handleDeleteFlow(e)}>
                        Delete
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Popper>
            </div>
            {openTemplateFlag ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
        </div>
      )}

      <Collapse in={openTemplateFlag} timeout="auto" unmountOnExit>
        <EmailFlowTemplate
          handleSelectTemplate={handleSelectTemplate}
          handleAddTemplate={handleAddTemplate}
          email={emailFlow}
          templateList={templateList}
          trigerredMails={trigerredMails}
          scheduledMails={scheduledMails}
          customMails={customMails}
          initialMailTemplate={initialMailTemplate}
          licenseValidatedTemplate={licenseValidatedTemplate}
          licenseRejectedTemplate={licenseRejectedTemplate}
          paymentRecievedTemplate={paymentRecievedTemplate}
          discountEndTemplate={discountEndTemplate}
        />
      </Collapse>
    </div>
  );
}

export default EmailFlowRow;
