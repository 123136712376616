import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import styles from "./SmtpSetup.module.css";
import TextfieldInput from "@_components/TextfieldInput/TextfieldInput";
import { emailSetupActions } from "@_actions/email_setup.actions";
import ValidateEmailSetupPopup from "@_components/popups/ValidateEmailSetupPopup/ValidateEmailSetupPopup";
import { checkGoogleServer, getUserDataFromJwt } from "@_utils/helpers";
import { isNull } from "@_utils/helpers";

import { makeStyles } from "@material-ui/core";
import { Button } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import Checkbox from "@material-ui/core/Checkbox";
import InboundEmailSetup from "@_components/InboundEmailSetup/InboundEmailSetup";

const useStyles = makeStyles({
  root: {
    paddingLeft: 0,
  },
});

function SmtpSetup() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const jwtData = getUserDataFromJwt();

  const emailSetupReducer = useSelector((state) => state.emailSetupReducer);

  const [openValidatePopup, setOpenValidatePopup] = useState(false);
  const [isGoogle, setIsGoogle] = useState(false);

  const [smtpDetails, setSmtpDetails] = useState({
    smtp_host: emailSetupReducer.email_setup.smtp_host || "",
    smtp_port: emailSetupReducer.email_setup.smtp_port || "",
    smtp_email: emailSetupReducer.email_setup.smtp_email || "",
    smtp_password: emailSetupReducer.email_setup.smtp_password || "",
    smtp_username:
      emailSetupReducer.email_setup.smtp_username ===
      emailSetupReducer.email_setup.smtp_email
        ? ""
        : emailSetupReducer.email_setup.smtp_username || "",
  });
  const [isClicked, setIsClicked] = useState(false);

  const [checkbox, setCheckbox] = useState(false);

  useEffect(() => {
    if (
      smtpDetails.smtp_username &&
      smtpDetails.smtp_email &&
      smtpDetails.smtp_username !== smtpDetails.smtp_email
    ) {
      setCheckbox(true);
    }
  }, [smtpDetails.smtp_username, smtpDetails.smtp_email]);

  useEffect(() => {
    if (checkGoogleServer(smtpDetails.smtp_host)) setIsGoogle(true);
    if (emailSetupReducer.status.EMAIL_SETUP_PUT === "success") {
      dispatch(emailSetupActions.getEmailSetup());
    }
  }, [dispatch, emailSetupReducer.status.EMAIL_SETUP_PUT, smtpDetails]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "smtp_host" && checkGoogleServer(value)) setIsGoogle(true);
    else setIsGoogle(false);
    setSmtpDetails((smtpDetails) => ({
      ...smtpDetails,
      [name]: value,
    }));
  };

  const handleUpdateEmailSetup = (activate = false) => {
    setIsClicked(true);

    if (!smtpDetails.smtp_username && !checkbox) {
      let data = {
        ...smtpDetails,
        smtp_port: parseInt(smtpDetails.smtp_port),
        smtp_username: smtpDetails.smtp_email,
      };
      dispatch(emailSetupActions.updateOutboundEmailSetup(data));
    } else {
      let data = {
        ...smtpDetails,
        smtp_port: parseInt(smtpDetails.smtp_port),
      };
      dispatch(emailSetupActions.updateOutboundEmailSetup(data));
    }

    let activationData = {
      active_email_setup: "SMTP",
    };

    setTimeout(() => {
      if (activate) {
        dispatch(emailSetupActions.activateOutboundEmailSetup(activationData));
      }
    }, 400);
  };

  const handleValidateSetup = (e) => {
    setIsClicked(true);

    if (!smtpDetails.smtp_username && !checkbox) {
      let data = {
        ...smtpDetails,
        smtp_port: parseInt(smtpDetails.smtp_port),
        smtp_username: smtpDetails.smtp_email,
      };
      dispatch(emailSetupActions.validateOutboundEmailSetup(data, "smtp"));
    } else {
      let data = {
        ...smtpDetails,
        smtp_port: parseInt(smtpDetails.smtp_port),
      };
      dispatch(emailSetupActions.validateOutboundEmailSetup(data, "smtp"));
    }
    setOpenValidatePopup(true);
  };

  useEffect(() => {
    if (emailSetupReducer.status.EMAIL_SETUP_PUT === "success") {
      dispatch(emailSetupActions.getEmailSetup());
    }
  }, [emailSetupReducer.status.EMAIL_SETUP_PUT, dispatch]);

  useEffect(() => {
    if (emailSetupReducer.status.EMAIL_SETUP_ACTIVATION_PUT === "success") {
      dispatch(emailSetupActions.getEmailSetup());
    }
  }, [emailSetupReducer.status.EMAIL_SETUP_ACTIVATION_PUT, dispatch]);

  return (
    <div>
      <div className={styles.textContainer}>
        <h2>Own Mail Server</h2>
        <div className={styles.smtp_text}>
          <p>
            The own mail server option allows you to connect an existing email
            account with Fair Licensing and send emails right from Fair
            Licensing. This may either be an existing account in your
            organization or an email account you set up specifically for the use
            with Fair Licensing, e.g. fairlicensing@example.com. All you need to
            do to enable this option is to configure which SMTP server to use
            and which email and password to use. <br></br>
            <br></br>Pro: Send emails with your own email address right from the
            Fair Licensing portal. This allows for highly effective workflows
            and a great user experience. All emails sent this way are logged
            right with the respective cases.<br></br>
            <br></br> Con: Requires five minutes of setup and you will have to
            store your email and password with Fair Licensing.
          </p>
          {isGoogle && (
            <p>
              Please make sure to setup the following requirement:{" "}
              <a
                className={styles.link}
                href="https://support.google.com/accounts/answer/6010255?hl=en#zippy="
              >
                Google account
              </a>
            </p>
          )}
        </div>

        <div>
          <div className={styles.input_row}>
            <div>
              <div className={styles.input_container}>
                <TextfieldInput
                  id={"smtp_host"}
                  name={"smtp_host"}
                  value={smtpDetails.smtp_host}
                  onChange={handleInputChange}
                  required
                  error={isClicked && isNull(smtpDetails.smtp_host)}
                  label="SMTP Host"
                />
              </div>
            </div>
            <div>
              <div className={styles.input_container}>
                {" "}
                <TextfieldInput
                  id={"smtp_port"}
                  name={"smtp_port"}
                  value={smtpDetails.smtp_port}
                  onChange={handleInputChange}
                  required
                  error={isClicked && isNull(smtpDetails.smtp_port)}
                  label="SMTP Port"
                />
              </div>
            </div>
          </div>
          <div className={styles.input_row}>
            <div>
              <div className={styles.input_container}>
                <TextfieldInput
                  id={"smtp_email"}
                  name={"smtp_email"}
                  value={smtpDetails.smtp_email}
                  onChange={handleInputChange}
                  required
                  error={isClicked && isNull(smtpDetails.smtp_email)}
                  label="Email"
                />
              </div>
            </div>

            <div>
              <div className={styles.input_container}>
                {" "}
                <TextfieldInput
                  id={"smtp_password"}
                  name={"smtp_password"}
                  value={smtpDetails.smtp_password}
                  onChange={handleInputChange}
                  required
                  error={isClicked && isNull(smtpDetails.smtp_password)}
                  type="password"
                  label="Password"
                />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.checkbox_container}>
          <Checkbox
            checked={checkbox}
            onChange={() => {
              setCheckbox(!checkbox);
              setSmtpDetails({
                ...smtpDetails,
                smtp_username: "",
              });
            }}
            className={classes.root}
          />
          <p>The smtp username differs from the email address</p>
        </div>
        {checkbox && (
          <div>
            <div className={styles.input_container}>
              <TextfieldInput
                id={"smtp_username"}
                name={"smtp_username"}
                value={smtpDetails.smtp_username}
                onChange={handleInputChange}
                required
                error={isClicked && isNull(smtpDetails.smtp_username)}
                label="Username"
              />
            </div>
          </div>
        )}
      </div>
      {emailSetupReducer.status.EMAIL_SETUP_VALIDATION_PUT === "request" ||
      emailSetupReducer.status.EMAIL_SETUP_ACTIVATION_PUT === "request" ||
      emailSetupReducer.status.EMAIL_SETUP_PUT === "request" ? (
        <div className={styles.circular_progress_container}>
          <CircularProgress />
        </div>
      ) : (
        <>
          <div className={styles.btn_container_validate}>
            <Button
              className={styles.validate_btn}
              onClick={() => handleValidateSetup()}
            >
              validate my setup
            </Button>
          </div>
          {emailSetupReducer.email_setup &&
          emailSetupReducer.email_setup.active_email_setup !== "SMTP" ? (
            <div className={styles.btn_container}>
              <Button
                className={styles.save_activate_btn}
                onClick={() => handleUpdateEmailSetup(true)}
              >
                Save & Activate
              </Button>
            </div>
          ) : (
            <div className={styles.btn_container}>
              <Button
                className={styles.save_activate_btn}
                onClick={() => handleUpdateEmailSetup()}
              >
                Save
              </Button>
            </div>
          )}
          <InboundEmailSetup />

          <ValidateEmailSetupPopup
            open={openValidatePopup}
            close={() => setOpenValidatePopup(false)}
            id={jwtData.customer_id}
            smtpData={smtpDetails}
            checkbox={checkbox}
          />
        </>
      )}
    </div>
  );
}

export default SmtpSetup;
