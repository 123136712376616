import React, { useState, useEffect } from "react";
import styles from "./InvoiceTemplateEditor.module.css";
import {
  Button,
  CircularProgress,
  MenuItem,
  Snackbar,
} from "@material-ui/core";
import TextfieldInput from "@_components/TextfieldInput/TextfieldInput";
import { useDispatch, useSelector } from "react-redux";
import TaxTypes from "@_configs/TaxType.config";
import { isNull } from "@_utils/helpers";
import { invoiceTemplateActions } from "@_actions/invoiceTemplate.actions";
import { Alert } from "@material-ui/lab";

function InvoiceTemplateEditor(props) {
  const { data } = props;
  const [templateConfig, setTemplateConfig] = useState({
    name: data?.name || "",
    tax_type: data?.tax_type || null,
    invoice_prefix: data?.invoice_prefix || "",
    label_total_gross: data?.label_total_gross || "",
    label_own_vat_id: data?.label_own_vat_id || "",
    label_client_vat_id: data?.label_client_vat_id || "",
    label_ref_no: data?.label_ref_no || "",
    label_invoice_name: data?.label_invoice_name || "",
    label_bill_to: data?.label_bill_to || "",
    label_invoice_no: data?.label_invoice_no || "",
    id: data?.id,
    label_invoice_date: data?.label_invoice_date || "",
    txt_header: data?.txt_header || "",
    label_item_number: data?.label_item_number || "",
    label_description: data?.label_description || "",
    label_amount: data?.label_amount || "",
    label_subtotal_net: data?.label_subtotal_net || "",
    txt_tax_name: data?.txt_tax_name || "",
    txt_discount_disclaimer: data?.txt_discount_disclaimer || "",
    txt_footer: data?.txt_footer || "",
    label_cancellation_invoice_name:
      data?.label_cancellation_invoice_name || "",
    txt_cancellation_header: data?.txt_cancellation_header || "",
    txt_cancellation_footer: data?.txt_cancellation_footer || "",
    label_cancelled_invoice_date: data?.label_cancelled_invoice_date || "",
    label_cancelled_invoice_no: data?.label_cancelled_invoice_no || "",
    label_case_id: data?.label_case_id || "",
    label_case_pin: data?.label_case_pin || "",
    txt_payment_instructions: data?.txt_payment_instructions || "",
  });
  const [trySave, setTrySave] = useState(false);
  const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setIsLoading] = useState(false);
  const invoiceTemplateReducer = useSelector(
    (state) => state.invoiceTemplateReducer
  );
  const dispatch = useDispatch();

  useEffect(() => {
    setTemplateConfig({
      name: data?.name || templateConfig.name || "",
      tax_type: data?.tax_type || templateConfig.tax_type || null,
      invoice_prefix:
        data?.invoice_prefix || templateConfig.invoice_prefix || "",
      label_total_gross:
        data?.label_total_gross || templateConfig.label_total_gross || "",
      label_own_vat_id:
        data?.label_own_vat_id || templateConfig.label_own_vat_id || "",
      label_client_vat_id:
        data?.label_client_vat_id || templateConfig.label_client_vat_id || "",
      label_ref_no: data?.label_ref_no || templateConfig.label_ref_no || "",
      label_invoice_name:
        data?.label_invoice_name || templateConfig.label_invoice_name || "",
      label_bill_to: data?.label_bill_to || templateConfig.label_bill_to || "",
      label_invoice_no:
        data?.label_invoice_no || templateConfig.label_invoice_no || "",
      id: data?.id,
      label_invoice_date:
        data?.label_invoice_date || templateConfig.label_invoice_date || "",
      txt_header: data?.txt_header || templateConfig.txt_header || "",
      label_item_number:
        data?.label_item_number || templateConfig.label_item_number || "",
      label_description:
        data?.label_description || templateConfig.label_description || "",
      label_amount: data?.label_amount || templateConfig.label_amount || "",
      label_subtotal_net:
        data?.label_subtotal_net || templateConfig.label_subtotal_net || "",
      txt_tax_name: data?.txt_tax_name || templateConfig.txt_tax_name || "",
      txt_discount_disclaimer:
        data?.txt_discount_disclaimer ||
        templateConfig.txt_discount_disclaimer ||
        "",
      txt_footer: data?.txt_footer || templateConfig.txt_footer || "",
      label_cancellation_invoice_name:
        data?.label_cancellation_invoice_name ||
        templateConfig.label_cancellation_invoice_name ||
        "",
      txt_cancellation_header:
        data?.txt_cancellation_header ||
        templateConfig.txt_cancellation_header ||
        "",
      txt_cancellation_footer:
        data?.txt_cancellation_footer ||
        templateConfig.txt_cancellation_footer ||
        "",
      label_cancelled_invoice_date:
        data?.label_cancelled_invoice_date ||
        templateConfig.label_cancelled_invoice_date ||
        "",
      label_cancelled_invoice_no:
        data?.label_cancelled_invoice_no ||
        templateConfig.label_cancelled_invoice_no ||
        "",
      label_case_id: data?.label_case_id || templateConfig.label_case_id || "",
      label_case_pin:
        data?.label_case_pin || templateConfig.label_case_pin || "",
      txt_payment_instructions:
        data?.txt_payment_instructions ||
        templateConfig.txt_payment_instructions ||
        "",
    });
    // eslint-disable-next-line
  }, [data]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenErrorSnackbar(false);
    setErrorMessage("");
  };

  useEffect(() => {
    if (
      invoiceTemplateReducer.status.INVOICE_TEMPLATE_CREATE === "request" ||
      invoiceTemplateReducer.status.INVOICE_TEMPLATE_UPDATE === "request" ||
      invoiceTemplateReducer.status.INVOICE_TEMPLATE_PREVIEW === "request"
    )
      setIsLoading(true);
    else setIsLoading(false);
  }, [
    dispatch,
    invoiceTemplateReducer.status.INVOICE_TEMPLATE_CREATE,
    invoiceTemplateReducer.status.INVOICE_TEMPLATE_UPDATE,
    invoiceTemplateReducer.status.INVOICE_TEMPLATE_PREVIEW,
  ]);

  const handleConfigChange = (event, isNumber) => {
    if (isNumber)
      setTemplateConfig({
        ...templateConfig,
        [event.target.name]: parseFloat(event.target.value),
      });
    else
      setTemplateConfig({
        ...templateConfig,
        [event.target.name]: event.target.value,
      });
  };
  const checkMissingRequiredFields = () => {
    return !templateConfig.name ||
      !templateConfig.tax_type ||
      !templateConfig.invoice_prefix ||
      !templateConfig.label_invoice_date ||
      !templateConfig.label_total_gross ||
      !templateConfig.label_invoice_name ||
      !templateConfig.label_bill_to ||
      !templateConfig.label_invoice_no ||
      !templateConfig.label_item_number ||
      !templateConfig.label_description ||
      !templateConfig.txt_discount_disclaimer ||
      !templateConfig.label_amount ||
      !templateConfig.label_subtotal_net ||
      !templateConfig.txt_tax_name ||
      !templateConfig.label_cancellation_invoice_name ||
      !templateConfig.label_cancelled_invoice_no ||
      !templateConfig.label_cancelled_invoice_date ||
      !templateConfig.label_case_id ||
      !templateConfig.label_case_pin ||
      !templateConfig.txt_payment_instructions ||
      !templateConfig.txt_discount_disclaimer.includes(
        "{{discount_percentage}}"
      ) ||
      !templateConfig.txt_discount_disclaimer.includes("{{discount_date}}")
      ? true
      : false;
  };
  const handleSave = () => {
    setTrySave(true);

    if (!checkMissingRequiredFields()) {
      setTrySave(false);
      templateConfig.id
        ? dispatch(invoiceTemplateActions.updateInvoiceTemplate(templateConfig))
        : dispatch(
            invoiceTemplateActions.createInvoiceTemplate(templateConfig)
          );
    } else {
      setOpenErrorSnackbar(true);
      setErrorMessage("Please fill all required fields.");
    }
  };

  const handlePreview = (isCancellation) => {
    setTrySave(true);
    if (!checkMissingRequiredFields()) {
      setTrySave(false);
      dispatch(
        invoiceTemplateActions.previewInvoiceTemplate({
          is_cancellation_invoice: isCancellation,
          invoice_template: templateConfig,
        })
      );
    } else {
      setOpenErrorSnackbar(true);
      setErrorMessage("Please fill all required fields.");
    }
  };
  return (
    <div className={styles.editor_root}>
      {errorMessage && (
        <Snackbar
          open={openErrorSnackbar}
          autoHideDuration={4000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity="error">
            {errorMessage}
          </Alert>
        </Snackbar>
      )}
      <div className={styles.header}>
        <div className={styles.template_header}>
          <TextfieldInput
            label="Template Name"
            placeholder="Template Name"
            name="name"
            inputProps={{ tabIndex: "1" }}
            value={templateConfig.name}
            onChange={(event) => handleConfigChange(event, false)}
            required
            error={trySave && !templateConfig.name}
          />
          <div className={styles.TwoInputs}>
            <TextfieldInput
              label="Tax Type"
              placeholder="Tax Type"
              select
              inputProps={{ tabIndex: "2" }}
              className={styles.subInput}
              name="tax_type"
              value={templateConfig.tax_type}
              onChange={(event) => handleConfigChange(event, false)}
              required
              error={trySave && isNull(templateConfig.tax_type)}
            >
              {TaxTypes.map((TaxType) => (
                <MenuItem key={TaxType.id} value={TaxType.id}>
                  {`${TaxType.label} `}
                </MenuItem>
              ))}
            </TextfieldInput>
            <TextfieldInput
              label="Invoice Prefix"
              inputProps={{ tabIndex: "3" }}
              placeholder="e.g. 'FL'"
              className={styles.subInput}
              name="invoice_prefix"
              value={templateConfig.invoice_prefix}
              onChange={(event) => handleConfigChange(event, false)}
              required
              error={trySave && !templateConfig.invoice_prefix}
            />
          </div>
        </div>
        <div className={styles.template_action_container}>
          {loading ? (
            <div className={styles.loading}>
              <CircularProgress color="primary" />
            </div>
          ) : (
            <Button
              onClick={handleSave}
              className={styles.confirm_btn}
              tabIndex={29}
            >
              Save
            </Button>
          )}
        </div>
      </div>
      <div className={styles.sub_header_row}>
        <h2>Invoice</h2>
        {loading ? (
          <div className={styles.loading}>
            <CircularProgress color="primary" />
          </div>
        ) : (
          <Button
            variant="outlined"
            tabIndex={30}
            color="default"
            className={styles.preview_button}
            onClick={() => handlePreview(false)}
          >
            Preview
          </Button>
        )}
      </div>

      <div className={styles.block}>
        <div className={styles.left}>
          <TextfieldInput
            label="Invoice name label"
            inputProps={{ tabIndex: "4" }}
            placeholder="e.g. 'Invoice'"
            name="label_invoice_name"
            value={templateConfig.label_invoice_name}
            onChange={(event) => handleConfigChange(event, false)}
            required
            error={trySave && !templateConfig.label_invoice_name}
          />
          <TextfieldInput
            label="Bill to label"
            inputProps={{ tabIndex: "6" }}
            placeholder="e.g. 'Bill to'"
            name="label_bill_to"
            value={templateConfig.label_bill_to}
            onChange={(event) => handleConfigChange(event, false)}
            required
            error={trySave && !templateConfig.label_bill_to}
          />
          <TextfieldInput
            label="Client VAT ID label"
            inputProps={{ tabIndex: "8" }}
            placeholder="e.g. 'VAT ID'"
            name="label_client_vat_id"
            value={templateConfig.label_client_vat_id}
            onChange={(event) => handleConfigChange(event, false)}
          />
          <TextfieldInput
            label="Client reference number"
            inputProps={{ tabIndex: "10" }}
            placeholder="e.g. 'Reference number'"
            name="label_ref_no"
            value={templateConfig.label_ref_no}
            onChange={(event) => handleConfigChange(event, false)}
          />
        </div>
        <div className={styles.right}>
          <TextfieldInput
            label="Invoice number label"
            inputProps={{ tabIndex: "5" }}
            placeholder="e.g. 'Invoice number'"
            name="label_invoice_no"
            value={templateConfig.label_invoice_no}
            onChange={(event) => handleConfigChange(event, false)}
            required
            error={trySave && !templateConfig.label_invoice_no}
          />{" "}
          <TextfieldInput
            label="Invoice date label"
            inputProps={{ tabIndex: "7" }}
            placeholder="e.g. 'Invoice date'"
            name="label_invoice_date"
            value={templateConfig.label_invoice_date}
            onChange={(event) => handleConfigChange(event, false)}
            required
            error={trySave && !templateConfig.label_invoice_date}
          />{" "}
          <TextfieldInput
            label="Own VAT ID label"
            inputProps={{ tabIndex: "9" }}
            placeholder="e.g. 'VAT ID'"
            name="label_own_vat_id"
            value={templateConfig.label_own_vat_id}
            onChange={(event) => handleConfigChange(event, false)}
          />
        </div>
      </div>
      <TextfieldInput
        label="Header"
        inputProps={{ tabIndex: "11" }}
        placeholder="Text shown above the invoice item list."
        multiline
        name="txt_header"
        value={templateConfig.txt_header}
        onChange={(event) => handleConfigChange(event, false)}
      />
      <div className={styles.block}>
        <div className={styles.left3}>
          <TextfieldInput
            label="Item number label"
            inputProps={{ tabIndex: "12" }}
            placeholder="e.g. '#'"
            name="label_item_number"
            value={templateConfig.label_item_number}
            onChange={(event) => handleConfigChange(event, false)}
            required
            error={trySave && !templateConfig.label_item_number}
          />
          <TextfieldInput
            label="Subtotal net label"
            inputProps={{ tabIndex: "15" }}
            placeholder="e.g. 'Subtotal net'"
            name="label_subtotal_net"
            value={templateConfig.label_subtotal_net}
            onChange={(event) => handleConfigChange(event, false)}
            required
            error={trySave && !templateConfig.label_subtotal_net}
          />
        </div>
        <div className={styles.middle3}>
          <TextfieldInput
            label="Description label"
            inputProps={{ tabIndex: "13" }}
            placeholder="e.g. 'Description'"
            name="label_description"
            value={templateConfig.label_description}
            onChange={(event) => handleConfigChange(event, false)}
            required
            error={trySave && !templateConfig.label_description}
          />
          <TextfieldInput
            label="Tax name label"
            inputProps={{ tabIndex: "16" }}
            placeholder="e.g. 'VAT'"
            name="txt_tax_name"
            value={templateConfig.txt_tax_name}
            onChange={(event) => handleConfigChange(event, false)}
            required
            error={trySave && !templateConfig.txt_tax_name}
          />
        </div>
        <div className={styles.right3}>
          <TextfieldInput
            label="Amount label"
            inputProps={{ tabIndex: "14" }}
            placeholder="e.g. 'Amount'"
            name="label_amount"
            value={templateConfig.label_amount}
            onChange={(event) => handleConfigChange(event, false)}
            required
            error={trySave && !templateConfig.label_amount}
          />
          <TextfieldInput
            label="Total Gross label"
            inputProps={{ tabIndex: "17" }}
            placeholder="e.g. 'Total gross'"
            name="label_total_gross"
            value={templateConfig.label_total_gross}
            onChange={(event) => handleConfigChange(event, false)}
            required
            error={trySave && !templateConfig.label_total_gross}
          />
        </div>
      </div>
      <TextfieldInput
        label="Discount disclaimer"
        inputProps={{ tabIndex: "18" }}
        multiline
        placeholder="e.g. 'We offer a {{discount_percentage}}% early payment discount, if this invoice is settled before {{discount_date}}.'"
        name="txt_discount_disclaimer"
        value={templateConfig.txt_discount_disclaimer}
        onChange={(event) => handleConfigChange(event, false)}
        required
        error={
          trySave &&
          (!templateConfig.txt_discount_disclaimer ||
            !templateConfig.txt_discount_disclaimer.includes(
              "{{discount_percentage}}"
            ) ||
            !templateConfig.txt_discount_disclaimer.includes(
              "{{discount_date}}"
            ))
        }
      />
      <p className={styles.description}>
        {" "}
        The discount disclaimer must include the {"{"}
        {"{"}discount_percentage{"}"}
        {"}"} and {"{"}
        {"{"}discount_date{"}"}
        {"}"} placeholders and will only be rendered, if the respective case has
        a discount set and the discount date is still in the future.
      </p>
      <TextfieldInput
        label="Footer"
        inputProps={{ tabIndex: "19" }}
        placeholder="Text shown below the invoice item list."
        multiline
        name="txt_footer"
        value={templateConfig.txt_footer}
        onChange={(event) => handleConfigChange(event, false)}
      />
      <TextfieldInput
        label="Payment instructions"
        inputProps={{ tabIndex: "20" }}
        placeholder="e.g 'To pay this invoice, log in to the settlement portal using the below credentials.'"
        multiline
        name="txt_payment_instructions"
        required
        value={templateConfig.txt_payment_instructions}
        onChange={(event) => handleConfigChange(event, false)}
        error={trySave && !templateConfig.txt_payment_instructions}
      />
      <p className={styles.description}>
        {" "}
        The payment instructions will only be rendered if the payment is still
        outstanding.
      </p>
      <div className={styles.block}>
        <div className={styles.left}>
          <TextfieldInput
            label="Case ID label"
            inputProps={{ tabIndex: "21" }}
            placeholder="e.g. 'Case ID' "
            name="label_case_id"
            required
            value={templateConfig.label_case_id}
            onChange={(event) => handleConfigChange(event, false)}
            error={trySave && !templateConfig.label_case_id}
          />
        </div>
        <div className={styles.right}>
          <TextfieldInput
            label="Case PIN label"
            inputProps={{ tabIndex: "22" }}
            placeholder="e.g. 'PIN'"
            name="label_case_pin"
            required
            value={templateConfig.label_case_pin}
            onChange={(event) => handleConfigChange(event, false)}
            error={trySave && !templateConfig.label_case_pin}
          />
        </div>
      </div>
      <div className={styles.sub_header_row}>
        <h2>Cancellation Invoice</h2>
        {loading ? (
          <div className={styles.loading}>
            <CircularProgress color="primary" />
          </div>
        ) : (
          <Button
            variant="outlined"
            tabIndex={31}
            color="default"
            className={styles.preview_button}
            onClick={() => handlePreview(true)}
          >
            Preview
          </Button>
        )}
      </div>

      <div className={styles.block}>
        <div className={styles.left}>
          {" "}
          <TextfieldInput
            label="Cancellation invoice name label"
            inputProps={{ tabIndex: "23" }}
            placeholder="e.g. 'Cancellation Invoice'"
            name="label_cancellation_invoice_name"
            value={templateConfig.label_cancellation_invoice_name}
            onChange={(event) => handleConfigChange(event, false)}
            required
            error={trySave && !templateConfig.label_cancellation_invoice_name}
          />
        </div>
        <div className={styles.right}>
          <TextfieldInput
            label="Cancelled invoice number label"
            inputProps={{ tabIndex: "24" }}
            placeholder="e.g. 'Cancelled invoice number'"
            name="label_cancelled_invoice_no"
            required
            value={templateConfig.label_cancelled_invoice_no}
            onChange={(event) => handleConfigChange(event, false)}
            error={trySave && !templateConfig.label_cancelled_invoice_no}
          />
          <TextfieldInput
            label="Cancelled invoice date label"
            inputProps={{ tabIndex: "25" }}
            placeholder="e.g. 'Cancelled invoice date'"
            name="label_cancelled_invoice_date"
            required
            error={trySave && !templateConfig.label_cancelled_invoice_date}
            value={templateConfig.label_cancelled_invoice_date}
            onChange={(event) => handleConfigChange(event, false)}
          />
        </div>
      </div>
      <TextfieldInput
        label="Cancellation header"
        inputProps={{ tabIndex: "26" }}
        placeholder="Text shown above the invoice item list."
        multiline
        name={"txt_cancellation_header"}
        value={templateConfig.txt_cancellation_header}
        onChange={(event) => handleConfigChange(event, false)}
      />
      <TextfieldInput
        label="Cancellation footer"
        inputProps={{ tabIndex: "27" }}
        placeholder="Text shown below the invoice item list."
        multiline
        name={"txt_cancellation_footer"}
        value={templateConfig.txt_cancellation_footer}
        onChange={(event) => handleConfigChange(event, false)}
      />
      {loading ? (
        <div className={styles.loading}>
          <CircularProgress color="primary" />
        </div>
      ) : (
        <Button
          onClick={handleSave}
          tabIndex={28}
          style={{ float: "right" }}
          className={styles.confirm_btn}
        >
          Save
        </Button>
      )}
    </div>
  );
}

export default InvoiceTemplateEditor;
