import React, { useState, useRef, useEffect } from 'react';
import styles from './AccountCreation.module.css';
import Stepper from './components/Stepper/Stepper';
import PersonalInformation from './components/PersonalInformation/PersonalInformation';
import Footer from './components/Footer/Footer';
import BusinessInformation from './components/BusinessInformation/BusinessInformation';
import TermsOfService from './components/TermsOfService/TermsOfService';
import WelcomeToFairLicensing from './components/WelcomeToFairLicensing/WelcomeToFairLicensing';
import { validateEmail, validatePasswordRules } from '@_utils/helpers';
import ErrorAccountCreation from './components/ErrorAccountCreation/ErrorAccountCreation';
import { customerService } from '@_services/customer.service';
import EuCountryList from '@_configs/EuCountryList.config.js';
import StateList from '@_configs/StateList.config.js';

import Box from '@material-ui/core/Box';
import LinearProgress from '@material-ui/core/LinearProgress';
const AccountCreation = () => {
  const stepList = [
    'Personal Information',
    'Business Information',
    'Terms of Service',
    'Welcome to Fair Licensing',
  ];
  const form = useRef();
  const recaptchaRef = React.createRef();
  const [loading, setLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [responseError, setResponseError] = useState(false);
  const [checkbox, setCheckbox] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    customer_type: '',
    customer_name: '',
    address_line_1: '',
    address_line_2: '',
    postal_code: '',
    city: '',
    country: '',
    state: '',
    vat_id: '',
    tos_version: '',
    recaptcha_id: '',
    termsOfService: '',
  });

  const [formDataValidation, setFormDataValidation] = useState({
    name: '',
    email: '',
    password: '',
    customer_type: '',
    customer_name: '',
    address_line_1: '',
    address_line_2: '',
    postal_code: '',
    city: '',
    country: '',
    vat_id: '',
  });

  useEffect(() => {
    customerService
      .getTermsOfService()
      .then((terms) => {
        setFormData({
          ...formData,
          tos_version: terms.version,
          termsOfService: terms.content,
        });
      })
      .catch((error) => console.error(error));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validateForm0 = () =>
    Boolean(formData.name) &&
    Boolean(formData.email) &&
    Boolean(formData.password);

  const validateForm1 = () =>
    validateForm0() &&
    Boolean(formData.customer_type) &&
    Boolean(formData.customer_name) &&
    Boolean(formData.address_line_1) &&
    Boolean(formData.postal_code) &&
    Boolean(formData.city) &&
    Boolean(formData.country);

  const validateForm = () => {
    switch (currentStep) {
      case 0:
        return (
          form.current.reportValidity() &&
          validateForm0() &&
          validateEmail(formData.email) &&
          validatePasswordRules(formData.password)
        );
      case 1:
        return form.current.reportValidity() && validateForm1();
      case 2:
      case 3:
        return validateForm1();
      default:
        return;
    }
  };

  const resendEmail = () => {
    const data = { email: formData.email };
    customerService.sendVerficationEmail(data);
  };

  const nextStep = async () => {
    if (currentStep === 2) {
      if (!checkbox) {
        alert(
          'Please accept the “Terms of Service” by ticking the checkbox at the bottom of the page'
        );
        return;
      } else if (checkbox) {
        try {
          const token = await recaptchaRef.current.executeAsync();
          // eslint-disable-next-line
          const res = await handelSubmitForm(token);
        } catch (error) {
          setResponseError(true);
        }
        setCurrentStep(currentStep + 1);
        setLoading(false);
      }
    } else {
      if (
        currentStep === 1 &&
        validateForm() &&
        formData.country === 'DE' &&
        !formData.vat_id
      ) {
        setCurrentStep(currentStep + 1);
      } else if (
        currentStep === 1 &&
        validateForm() &&
        EuCountryList.includes(formData.country)
      ) {
        setLoading(true);
        try {
          const token = await recaptchaRef.current.executeAsync();
          // eslint-disable-next-line
          const res = await validateVat(token);
          if (validateForm() && res === '') {
            setCurrentStep(currentStep + 1);
          }
        } catch (error) {
          if (error.includes('Access forbidden')) {
            alert(error);
          } else {
            setFormDataValidation({
              ...formDataValidation,
              vat_id: 'Invalid VAT',
            });
          }
        }
        window.grecaptcha.reset();
        setLoading(false);
      } else if (validateForm()) {
        setCurrentStep(currentStep + 1);
      }
    }
  };

  const validateEmailFormat = (email) => {
    if (!validateEmail(email)) {
      setFormDataValidation({ ...formDataValidation, email: 'Wrong Email' });
    } else {
      setFormDataValidation({ ...formDataValidation, email: '' });
    }
  };

  const validatePassword = (password) => {
    if (!validatePasswordRules(password)) {
      setFormDataValidation({
        ...formDataValidation,
        password: 'Wrong password',
      });
    } else {
      setFormDataValidation({ ...formDataValidation, password: '' });
    }
  };

  const handleFormChange = (e) => {
    if (e.target.name === 'email') {
      validateEmailFormat(e.target.value);
    }
    if (e.target.name === 'password') {
      validatePassword(e.target.value);
    }
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const validateVat = async (token) => {
    if (formData.country && formData.vat_id) {
      const data = {
        country: formData.country,
        vat_id: formData.vat_id,
        recaptcha_token: token,
      };

      return customerService.validateVat(data);
    }
  };

  function handleChangeRecaptcha(value) {
    setFormData({
      ...formData,
      recaptcha_id: value,
    });
  }

  const handelSubmitForm = async (token) => {
    setLoading(true);
    const data = {
      name: formData.name,
      email: formData.email,
      password: formData.password,
      customer_type: formData.customer_type,
      customer_name: formData.customer_name,
      address_line_1: formData.address_line_1,
      postal_code: formData.postal_code,
      city: formData.city,
      country: formData.country,
      recaptcha_token: token,
      tos_version: formData.tos_version,
    };

    if (formData.country === 'US') {
      data['us_state'] = formData.state;
    }

    if (formData.address_line_2) {
      data['address_line_2'] = formData.address_line_2;
    }

    if (formData.vat_id) {
      data['vat_id'] = formData.vat_id;
    }
    return customerService.createAccount(data);
  };

  return (
    <div className={styles.accountCreation}>
      <div className={styles.accountCreationContainer}>
        <div className={styles.accountCreationHeader}>
          {'Create your fair licensing account'.toLocaleUpperCase()}
        </div>
        <div>
          <Stepper stepList={stepList} currentStep={currentStep} />
          {loading && (
            <Box sx={{ width: '100%' }}>
              <LinearProgress color="primary" />
            </Box>
          )}
          {currentStep === 0 && (
            <PersonalInformation
              {...formData}
              setFormData={setFormData}
              handleChangeRecaptcha={handleChangeRecaptcha}
              formDataValidation={formDataValidation}
              handleChange={handleFormChange}
              formRef={form}
            />
          )}
          {currentStep === 1 && (
            <BusinessInformation
              {...formData}
              formDataValidation={formDataValidation}
              handleChange={handleFormChange}
              formRef={form}
              EuCountryList={EuCountryList}
              StateList={StateList}
              recaptchaRef={recaptchaRef}
            />
          )}
          {currentStep === 2 && (
            <TermsOfService
              {...formData}
              checkboxstate={checkbox}
              setCheckbox={setCheckbox}
              recaptchaRef={recaptchaRef}
            />
          )}
          {!loading && currentStep === 3 && !responseError && (
            <WelcomeToFairLicensing email={formData.email} />
          )}
          {!loading && currentStep === 3 && responseError && (
            <ErrorAccountCreation email={formData.email} />
          )}

          <Footer
            handleSubmit={nextStep}
            currentStep={currentStep}
            responseError={responseError}
            resendEmail={resendEmail}
          />
        </div>
      </div>
    </div>
  );
};

export default AccountCreation;
